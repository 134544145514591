import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AddToListPopup from './AddToListPopup';

function AddToList({ game }) {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      {showPopup && (
        <div
          className="fixed inset-0 flex justify-center items-center bg-opacity-80 bg-black z-50"
          onClick={() => setShowPopup(false)}
          role="button"
          aria-label="Close"
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Escape' && setShowPopup(false)}
        >
          <AddToListPopup game={game} hidePopup={() => setShowPopup(false)} />
        </div>
      )}
      <button
        type="button"
        onClick={() => setShowPopup(true)}
        className="flex justify-center items-center w-full p-6 rounded-xl
                bg-admin dark:bg-admin-dark
                border border-transparent hover:border-black dark:hover:border-white"
      >
        <p className="text-3xl">Legg til i liste</p>
      </button>
    </>
  );
}

AddToList.propTypes = {
  game: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    numberOfPlayers: PropTypes.number.isRequired,
  }).isRequired,
};

export default AddToList;
