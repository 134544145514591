import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import databaseService from '../../services/DatabaseService';

function FilterPanel({ onFilterSelect }) {
  const [availCats, setAvailCats] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Finds all categories
  useEffect(() => {
    databaseService.getAvailableCategories().then((categories) => {
      setAvailCats(categories);
    });
  }, []);

  const toggleFilter = (filter) => {
    const isActive = activeFilters.includes(filter);
    if (isActive) {
      setActiveFilters(activeFilters.filter((f) => f !== filter));
    } else {
      setActiveFilters([...activeFilters, filter]);
    }
    onFilterSelect(filter);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="flex flex-col p-4 bg-gray-800 rounded">
      <button
        onClick={toggleDropdown}
        type="button"
        className="mb-4 text-lg font-semibold text-white flex items-center justify-between"
      >
        Filtrer
        <span className="ml-2">{dropdownOpen ? '▲' : '▼'} </span>
      </button>
      {dropdownOpen && (
        <div className="max-h-40 overflow-y-auto overflow-x-hidden">
          {availCats.map((filter) => (
            <button
              key={filter}
              type="button"
              onClick={() => toggleFilter(filter)}
              className={`flex items-center px-4 py-2 text-left rounded shadow mb-2 focus:outline-none
                                ${activeFilters.includes(filter) ? 'bg-gray-500 text-white' : 'bg-gray-800 text-blue-200 hover:bg-blue-600'}`}
            >
              <span
                className={`inline-block mr-2 ${activeFilters.includes(filter) ? 'text-yellow-400' : 'text-green-500'}`}
              >
                +
              </span>
              {filter}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

FilterPanel.propTypes = {
  onFilterSelect: PropTypes.func.isRequired,
};

export default FilterPanel;
