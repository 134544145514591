import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import databaseService from '../services/DatabaseService';
import RatingClass from '../services/RatingClass';

function UserCommentsAndRatings({ userID }) {
  const [gameFeedback, setGameFeedback] = useState({});
  const colors = ['bg-indigo-100', 'bg-sky-100', 'bg-slate-100', 'bg-blue-100'];
  const darkColors = [
    'dark:bg-[#1D334A]',
    'dark:bg-[#424163]',
    'dark:bg-[#34545E]',
    'dark:bg-[#34485E]',
  ];
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editText, setEditText] = useState('');

  /**
   * Updates the temporary rating for a game in the gameFeedback object.
   * @param {number} rating - The rating value to be set.
   * @param {string} gameID - The ID of the game.
   */
  const hoverFill = (rating, gameID) => {
    const feedbackCopy = { ...gameFeedback };
    feedbackCopy[gameID].tempRating = rating;
    setGameFeedback(feedbackCopy);
  };

  /**
   * Resets the temporary rating for a game.
   *
   * @param {string} gameID - The ID of the game.
   */
  const resetRating = (gameID) => {
    const feedbackCopy = { ...gameFeedback };
    delete feedbackCopy[gameID].tempRating;
    setGameFeedback(feedbackCopy);
  };

  /**
   * Updates the rating for a game.
   * @param {number} ratingValue - The new rating value.
   * @param {string} gameID - The ID of the game.
   * @returns {Promise<void>} - A promise that resolves when the rating is updated.
   */
  const updateRating = async (ratingValue, gameID) => {
    const gameName =
      gameFeedback[gameID]?.comments?.[0]?.gameName || 'Ukjent Spill';
    const ratingInstance = new RatingClass(
      null,
      userID,
      gameID,
      gameName,
      ratingValue,
    );

    const backupFeedback = { ...gameFeedback };

    const feedbackCopy = { ...gameFeedback };
    feedbackCopy[gameID].rating = ratingValue;
    setGameFeedback(feedbackCopy);

    const changed = await databaseService.addRating(ratingInstance);
    if (!changed) {
      setGameFeedback(backupFeedback);
      toast.error('Kunne ikke oppdatere vurderingen');
    }
  };

  /**
   * Deletes a comment from the game feedback.
   * @param {string} gameID - The ID of the game.
   * @param {string} commentID - The ID of the comment.
   */
  const handleDeleteComment = async (gameID, commentID) => {
    const deleted = await databaseService.deleteComment(commentID, userID);
    if (deleted) {
      // Update the local state to reflect the deletion
      const feedbackCopy = { ...gameFeedback };
      const gameFeedbackCopy = feedbackCopy[gameID];
      gameFeedbackCopy.comments = gameFeedbackCopy.comments.filter(
        (comment) => comment.ID !== commentID,
      );

      // Check if there are no more comments and no rating for the game
      if (
        gameFeedbackCopy.comments.length === 0 &&
        (gameFeedbackCopy.rating === 'no ratings yet' ||
          gameFeedbackCopy.rating === undefined)
      ) {
        // If there are no comments or ratings, remove the game from the feedback
        delete feedbackCopy[gameID];
      }

      setGameFeedback(feedbackCopy);
    } else {
      toast.error('Kunne ikke slette kommentaren.');
    }
  };

  /**
   * Updates the text of a comment in the game feedback.
   * @param {string} gameID - The ID of the game.
   * @param {string} commentID - The ID of the comment.
   * @param {string} newText - The new text for the comment.
   */
  const updateCommentInFeedback = (gameID, commentID, newText) => {
    const feedbackCopy = { ...gameFeedback };
    const gameFeedbackCopy = feedbackCopy[gameID];

    const commentIndex = gameFeedbackCopy.comments.findIndex(
      (comment) => comment.ID === commentID,
    );
    if (commentIndex !== -1) {
      gameFeedbackCopy.comments[commentIndex].text = newText;
      gameFeedbackCopy.comments[commentIndex].edited = true;
    }

    setGameFeedback(feedbackCopy);
  };

  const handleEditClick = (comment) => {
    setEditingCommentId(comment.ID);
    setEditText(comment.text);
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditText('');
  };

  /**
   * Handles the saving of an edited comment.
   *
   * @param {string} gameID - The ID of the game.
   * @param {string} commentID - The ID of the comment.
   * @returns {Promise<void>} - A promise that resolves when the comment is successfully updated.
   */
  const handleSaveEdit = async (gameID, commentID) => {
    const trimmedText = editText.trim();
    const originalText = gameFeedback[gameID].comments.find(
      (comment) => comment.ID === commentID,
    )?.text;

    // Check if the comment is empty
    if (trimmedText === '') {
      toast.error('Kommentar kan ikke være tom.');
      return;
    }

    // Check if the comment has been changed
    if (trimmedText === originalText) {
      // No actual change in the comment, so cancel the edit
      handleCancelEdit();
      return;
    }

    // Proceed with the comment update if there are changes
    const changed = await databaseService.updateComment(
      userID,
      commentID,
      trimmedText,
    );
    if (changed) {
      updateCommentInFeedback(gameID, commentID, trimmedText);
      handleCancelEdit();
    } else {
      toast.error('Kunne ikke oppdatere kommentaren.');
    }
  };

  useEffect(() => {
    /**
     * Fetches user comments and ratings from the database and updates the game feedback state.
     * @returns {Promise<void>} A promise that resolves when the data is fetched and the game feedback state is updated.
     */
    const fetchData = async () => {
      try {
        const userComments = await databaseService.getCommentsByUserID(userID);
        const userRatings = await databaseService.getRatingByUserID(userID);

        const feedback = {};

        // Initialize feedback entries for games with comments
        userComments.forEach((comment) => {
          if (!feedback[comment.gameID]) {
            feedback[comment.gameID] = {
              comments: [],
              gameName: comment.gameName,
            };
          }
          feedback[comment.gameID].comments.push(comment);
        });

        // Update or initialize feedback entries for games with ratings
        userRatings.forEach((rate) => {
          if (!feedback[rate.gameID]) {
            feedback[rate.gameID] = {
              comments: [],
              rating: rate.ratingValue,
              gameName: rate.gameName,
            };
          } else {
            feedback[rate.gameID].rating = rate.ratingValue;
          }
        });

        setGameFeedback(feedback);
      } catch (error) {
        toast.error('Feil ved hentig av data');
      }
    };

    fetchData();
  }, [userID]);

  return (
    <div className="space-y-6">
      {Object.keys(gameFeedback).map((gameID, index) => {
        const color = colors[index % colors.length];
        const darkColor = darkColors[index % colors.length];
        const rating =
          gameFeedback[gameID].tempRating || gameFeedback[gameID].rating;
        return (
          <div
            key={gameID}
            className={`p-4 rounded-lg shadow-lg flex flex-col ${color} ${darkColor} hover:border-black`}
          >
            <div className="flex justify-between items-center mb-3">
              <Link
                to={`../game/${gameID}`}
                className="text-xl font-semibold hover:text-blue-600 transition-colors duration-200 w-full h-full"
              >
                {gameFeedback[gameID].gameName || 'Spill Navn'}
              </Link>
              <div
                onMouseLeave={() => resetRating(gameID)}
                className="flex text-4xl cursor-pointer"
              >
                {[1, 2, 3, 4, 5].map((starIndex) => (
                  <button
                    key={starIndex}
                    onMouseOver={() => hoverFill(starIndex, gameID)}
                    onFocus={() => hoverFill(starIndex, gameID)}
                    type="button"
                    onClick={() => updateRating(starIndex, gameID)}
                    className={`mx-1 ${starIndex <= rating ? 'text-yellow-500' : 'text-gray-300'}`}
                  >
                    ★
                  </button>
                ))}
              </div>
            </div>
            <div className="overflow-y-auto" style={{ maxHeight: '11rem' }}>
              {gameFeedback[gameID].comments.length > 0 ? (
                gameFeedback[gameID].comments.map((comment) => (
                  <div
                    key={comment.ID}
                    className={`bg-quaternary dark:bg-quaternary-dark border border-commentBorder dark:border-commentBorder-dark p-2 rounded my-2 ${editingCommentId === comment.ID ? 'min-h-[5rem]' : 'min-h-[5rem]'}`}
                  >
                    {editingCommentId === comment.ID ? (
                      <>
                        <textarea
                          value={editText}
                          onChange={(e) => setEditText(e.target.value)}
                          className="w-full bg-quaternary dark:bg-quaternary-dark text-weakText dark:text-weakText-dark p-2 resize-none"
                          style={{ height: '5rem' }}
                        />
                        <div className="flex justify-end mt-2 space-x-1">
                          <button
                            type="button"
                            className="bg-blue-500 text-white p-1 rounded hover:bg-blue-600"
                            onClick={() => handleSaveEdit(gameID, comment.ID)}
                          >
                            Lagre
                          </button>
                          <button
                            type="button"
                            className="bg-gray-500 text-white p-1 rounded hover:bg-gray-600"
                            onClick={handleCancelEdit}
                          >
                            Avbryt
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="flex justify-between items-start">
                        <p className="text-gray-700 dark:text-white flex-grow">
                          {comment.text}
                        </p>
                        <button
                          type="button"
                          className="py-1 px-3 h-9 bg-blue-500 text-white rounded hover:bg-blue-600 ml-2"
                          onClick={() => handleEditClick(comment)}
                        >
                          Rediger
                        </button>
                        <button
                          type="button"
                          className="py-1 px-3 h-9 bg-red-500 text-white rounded hover:bg-red-600 ml-2"
                          onClick={() =>
                            handleDeleteComment(gameID, comment.ID)
                          }
                        >
                          Slett
                        </button>
                      </div>
                    )}
                    {editingCommentId !== comment.ID && (
                      <p className="text-sm text-gray-500 dark:text-gray-300 mt-1">
                        {comment.timestampAsString}{' '}
                        {comment.edited ? '(Redigert)' : ''}
                      </p>
                    )}
                  </div>
                ))
              ) : (
                <Link to={`../game/${gameID}`}>
                  <div className="text-center text-weakText dark:text-weakText-dark hover:text-blue-600">
                    Legg til en kommentar da vel!
                  </div>
                </Link>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

UserCommentsAndRatings.propTypes = {
  userID: PropTypes.string.isRequired,
};

export default UserCommentsAndRatings;
