import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import BackButton from './BackButton/BackButton';
import ShareGameButton from './ShareGameButton';
import Stopwatch from './Stopwatch';
import WriteReport from './WriteReport';

function GameDescription({ game, favoriteGames, onFavoriteClicked }) {
  const [isFavorited, setIsFavorited] = useState(false);
  const [isWriteReportVisible, setWriteReportVisible] = useState(false);

  const handleReportClick = () => {
    setWriteReportVisible(true);
  };

  const handleFavorite = () => {
    onFavoriteClicked();
  };

  useEffect(() => {
    setIsFavorited(false);
    for (let i = 0; i < favoriteGames.length; i += 1) {
      if (favoriteGames[i].gameID === game.gameID) {
        setIsFavorited(true);
        return;
      }
    }
  }, [favoriteGames, game.gameID]);

  const handleCloseClick = () => {
    setWriteReportVisible(false);
  };

  return (
    <section>
      <section className="flex flex-col items-center rounded-2xl bg-admin dark:bg-admin-dark w-full gap-4 pb-16">
        <div className="flex w-full justify-between items-center p-4">
          <BackButton />
          <div className="flex flex-col items-end">
            <div className="flex flex-row items-center gap-2">
              <ShareGameButton gameID={game.gameID} />
              <button
                type="button"
                className="bg-red-500 rounded-full px-2 cursor-pointer"
                onClick={handleReportClick}
              >
                Rapporter
              </button>
              <button type="button" onClick={handleFavorite}>
                <img
                  src={isFavorited ? '/red-heart.png' : '/heart.png'}
                  alt="hjerte"
                  className="max-h-10 cursor-pointer"
                />
              </button>
            </div>
            <div className="mt-2">
              <Stopwatch />
            </div>
          </div>
        </div>
        <h1 className="text-4xl text-center">{game.title.toUpperCase()}</h1>
        <div className="flex flex-row items-center gap-2">
          <p className="text-xl">{game.numberOfPeople}</p>
          <img src="/people.png" alt="personer" className="max-w-8" />
        </div>
        <div className="text-4xl">
          {game.rating >= 0 && game.rating <= 5
            ? '★ '.repeat(Math.round(game.rating)) +
              '☆ '.repeat(5 - Math.round(game.rating))
            : 'no rating'}
        </div>
        <div className="flex flex-row flex-wrap justify-center gap-4 max-w-[70%]">
          {game.categories ? (
            game.categories.map((category) => (
              <p
                key={category}
                className="bg-category dark:bg-category-dark rounded-full px-3 py-1 text-sm"
              >
                {category}
              </p>
            ))
          ) : (
            <p className="bg-[#d9d9d9] rounded-full px-3 text-sm">
              no categories
            </p>
          )}
        </div>
        <p className="max-w-[70%] text-xl mt-8 text-left whitespace-pre-line">
          {game.description}
        </p>
      </section>
      {isWriteReportVisible && (
        <WriteReport
          onClose={handleCloseClick}
          gameID={game.gameID}
          gameName={game.title}
        />
      )}
    </section>
  );
}

GameDescription.propTypes = {
  game: PropTypes.shape({
    gameID: PropTypes.string,
    title: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string),
    rating: PropTypes.number,
    numberOfPeople: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  favoriteGames: PropTypes.arrayOf(
    PropTypes.shape({
      gameID: PropTypes.string,
      title: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.string),
      rating: PropTypes.number,
      numberOfPeople: PropTypes.string,
      description: PropTypes.string,
    }),
  ).isRequired,
  onFavoriteClicked: PropTypes.func.isRequired,
};

export default GameDescription;
