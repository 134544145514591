import React from 'react';
import { Link } from 'react-router-dom';
import Plus from '../Icons/Plus';

function CreateGameCard() {
  return (
    <Link to="/newgame" relative="path" className="w-full h-full">
      <div className="flex flex-row flex-wrap justify-center items-center gap-4 w-full h-full rounded-lg border dark:border-gray-600 hover:border-black dark:hover:border-white">
        <h3 className="text-3xl font-medium">Ny lek</h3>
        <Plus size={8} />
      </div>
    </Link>
  );
}

export default CreateGameCard;
