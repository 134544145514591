/**
 * Class representing a comment.
 * @class
 *
 * @example
 * const comment = new CommentClass(
 *     "commentID",
 *     "creatorID",
 *     "creatorName",
 *     "creatorEmail",
 *     "gameID",
 *     "gameName",
 *     "This is a comment.",
 *     new Date(),
 * );
 */
export default class CommentClass {
  /**
   * Create a comment
   * @constructor
   *
   * @param {string} ID - The comment's ID.
   * @param {string} creatorID - The creator's userID.
   * @param {string} creatorName - The creator's name.
   * @param {string} creatorEmail - The creator's email.
   * @param {string} gameID - The gameID.
   * @param {string} gameName - The name of the game.
   * @param {string} text - The actual comment.
   * @param {Date} timestamp - Timestamp of when the comment was created.
   * @param {boolean} [edited=false] - Whether the comment has been edited. (default is false)
   */
  constructor(
    ID,
    creatorID,
    creatorName,
    creatorEmail,
    gameID,
    gameName,
    text,
    timestamp,
    edited = false,
  ) {
    this.ID = ID;
    this.creatorID = creatorID;
    this.creatorName = creatorName;
    this.creatorEmail = creatorEmail;
    this.gameID = gameID;
    this.gameName = gameName;
    this.text = text;
    this.timestamp = timestamp;
    this.edited = edited;
    this.timestampAsString = this.getTimestampAsString();
  }

  getTimestampAsString() {
    function pad(n) {
      return n < 10 ? `0${n}` : `${n}`;
    }
    const months = [
      'jan',
      'feb',
      'mar',
      'apr',
      'mai',
      'jun',
      'jul',
      'aug',
      'sep',
      'okt',
      'nov',
      'des',
    ];
    return `${pad(this.timestamp.getHours())}:${pad(
      this.timestamp.getMinutes(),
    )} ${this.timestamp.getDate()}. ${
      months[this.timestamp.getMonth()]
    } ${this.timestamp.getFullYear()}`;
  }
}
