import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AreYouSure from '../components/Admin-components/AreYouSure';
import ReportCard from '../components/Admin-components/ReportCard';
import GamesCard from '../components/GamesCard/GamesCard';
import { auth } from '../config/firebase';
import databaseService from '../services/DatabaseService';

function AdminPage({ games, setGames }) {
  const [showAreYouSure, setShowAreYouSure] = useState(false); // Add this state variable
  const [gameIdToDelete, setGameIdToDelete] = useState(null); // Add this state variable
  const [email, setEmail] = useState('');
  const [reports, setReports] = useState([]);

  useEffect(() => {
    setEmail(auth.currentUser.email);
  }, []);

  useEffect(() => {
    databaseService
      .getAllReports()
      .then((_reports) =>
        setReports(_reports.sort((a, b) => a.gameID.localeCompare(b.gameID))),
      );
  }, []);

  function toggleAreYouSure(gameId) {
    setShowAreYouSure(!showAreYouSure);
    setGameIdToDelete(gameId);
  }

  function onDelete(gameId) {
    const newGames = games.filter((game) => game.gameID !== gameId);
    setGames(newGames);
    setShowAreYouSure(false);
    setReports(reports.filter((report) => report.gameID !== gameId));
  }

  function closeAreYouSure() {
    setShowAreYouSure(false);
  }

  async function deleteReport(reportId) {
    if (reportId) {
      // Add this line
      const deleted = await databaseService.deleteReport(
        reportId,
        auth.currentUser.uid,
      );
      if (deleted) {
        setReports(reports.filter((report) => report.ID !== reportId));
      }
    }
  }

  const groupedReports = reports.reduce((acc, report) => {
    if (!acc[report.gameID]) {
      acc[report.gameID] = [];
    }
    acc[report.gameID].push(report);
    return acc;
  }, {});

  return (
    <section className="relative">
      <section className="flex flex-col text-center rounded-2xl bg-admin dark:bg-admin-dark w-auto py-20 px-12 gap-4 m-4">
        <h1 className="text-4xl mb-4">Admin</h1>
        <p className="text-1xl">E-post: {email || 'Ukjent'}</p>

        <div className="gap-2">
          <div className="flex items-center gap-4">
            <h2 className="text-3xl">Rapporteringer</h2>
            <img src="/utropstegn.png" alt="people" className="max-w-7" />
          </div>
          <div className="flex flex-col gap-6 m-6 ">
            {Object.entries(groupedReports).map(([gameId, gameReports]) => (
              <div
                key={gameId}
                className="w-full bg-report dark:bg-report-dark rounded-lg p-2 "
              >
                <h3 className="text-xl mt-3 w-2/4 text-left flex ml-5 mb-2">
                  <Link
                    to={`/game/${gameId}`}
                    relative="path"
                    className="text-xl"
                  >
                    {gameReports[0].gameName}
                  </Link>
                  <button
                    type="button"
                    className="text-xl underline w-2/4 text-right flex ml-10"
                    onClick={() => toggleAreYouSure(gameId)}
                  >
                    Slett lek
                  </button>
                </h3>
                {gameReports.map((report) => (
                  <div key={report.listID} className="w-full ">
                    <ReportCard
                      report={report}
                      toggleAreYouSure={(_gameId) => toggleAreYouSure(_gameId)}
                      deleteReport={(reportId) => deleteReport(reportId)}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="gap-2 mt-8">
          <h2 className="text-left text-3xl">Aktive leker</h2>
          <div className="flex flex-wrap justify-between gap-6 m-6">
            {games.map((game, index) => (
              <div key={game.gameID} className="flex w-full lg:max-w-[47%]">
                <GamesCard
                  game={game}
                  index={index}
                  toggleAreYouSure={(gameId) => toggleAreYouSure(gameId)}
                />
              </div>
            ))}
          </div>
        </div>
        {showAreYouSure && (
          <AreYouSure
            onDelete={(gameId) => onDelete(gameId)}
            gameIdToDelete={gameIdToDelete}
            closeAreYouSure={() => closeAreYouSure()}
          />
        )}
      </section>
    </section>
  );
}

AdminPage.propTypes = {
  games: PropTypes.arrayOf(
    PropTypes.shape({
      gameID: PropTypes.string,
      gameName: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      rating: PropTypes.number,
      creatorID: PropTypes.string,
    }),
  ).isRequired,
  setGames: PropTypes.func.isRequired,
};

export default AdminPage;
