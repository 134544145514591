import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import List from '../components/List';
import { auth } from '../config/firebase';
import databaseService from '../services/DatabaseService';

function MyListsPage({ favoriteGames, setFavoriteGames }) {
  const [lists, setLists] = useState([]);

  const handleListDelete = async (deletedListID) => {
    const successfulDeletion = await databaseService.deleteList(
      auth.currentUser.uid,
      deletedListID,
    );
    if (successfulDeletion) {
      const updatedListsCopy = lists.filter(
        (list) => list.listID !== deletedListID,
      );
      setLists(updatedListsCopy);
    }
  };

  useEffect(() => {
    databaseService.getLists(auth.currentUser.uid).then((fetchedLists) => {
      setLists(fetchedLists);
    });
  }, []);

  return (
    <div className="flex flex-col text-center rounded-2xl bg-admin dark:bg-admin-dark w-auto py-20 px-12 gap-4 m-4">
      <h1 className="text-4xl mb-4">Mine lister</h1>

      {lists.length === 0 ? (
        <p>Du har ingen lagrede lister.</p>
      ) : (
        lists.map((list) => (
          <List
            key={list.listID}
            list={list}
            favoriteGames={favoriteGames}
            setFavoriteGames={setFavoriteGames}
            onDeleteList={handleListDelete}
          />
        ))
      )}
    </div>
  );
}

MyListsPage.propTypes = {
  favoriteGames: PropTypes.arrayOf(
    PropTypes.shape({
      gameID: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      rating: PropTypes.number,
      creatorID: PropTypes.string,
    }),
  ).isRequired,
  setFavoriteGames: PropTypes.func.isRequired,
};

export default MyListsPage;
