import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { auth } from '../config/firebase';
import databaseService from '../services/DatabaseService';
import GamesCard from './GamesCard/GamesCard';

function List({ list, favoriteGames, setFavoriteGames, onDeleteList }) {
  const [gamesList, setGamesList] = useState(list.games);
  const [draggingItem, setDraggingItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [arrowSize, setArrowSize] = useState('w-10 h-10');

  const handleDragStart = (e, game) => {
    setDraggingItem(game);
    e.dataTransfer.setData('text/plain', '');
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetGame) => {
    if (!draggingItem) return;

    const currentIndex = gamesList.indexOf(draggingItem);
    const targetIndex = gamesList.indexOf(targetGame);
    if (currentIndex === targetIndex) {
      return;
    }

    if (currentIndex !== -1 && targetIndex !== -1) {
      const newGamesList = [...gamesList];
      newGamesList.splice(currentIndex, 1);
      newGamesList.splice(targetIndex, 0, draggingItem);
      setGamesList(newGamesList);
      databaseService.updateList(auth.currentUser.uid, list);
    }
  };

  async function deleteFromList(game) {
    const successfulDelete = await databaseService.removeGameFromList(
      auth.currentUser.uid,
      list.listID,
      game.gameID,
    );
    if (successfulDelete) {
      const newGamesList = gamesList.filter((g) => g.gameID !== game.gameID);
      setGamesList(newGamesList);

      if (newGamesList.length === 0) {
        const deleted = await databaseService.deleteList(
          auth.currentUser.uid,
          list.listID,
        );
        if (deleted) {
          onDeleteList(list.listID);
        }
      }
    }
  }

  return (
    <div className="flex flex-col h-full justify-center rounded-lg border dark:border-black bg-primary dark:bg-primary-dark hover:border-black dark:hover:border-white">
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="relative cursor-pointer"
        onMouseEnter={() => setArrowSize('w-12 h-12')}
        onMouseLeave={() => setArrowSize('w-10 h-10')}
      >
        <h1 className="text-4xl p-8">{list.title}</h1>
        <div className="absolute h-full flex items-center justify-center top-0 right-10 w-14">
          {open ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className={arrowSize}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 15.75 7.5-7.5 7.5 7.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className={arrowSize}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          )}
        </div>
        <button type="button" onClick={() => onDeleteList(list.listID)}>
          <img
            src="/bin.png"
            alt="bin"
            className="absolute -top-4 -right-4 z-10 h-12 cursor-pointer rounded-full border border-transparent hover:border-black dark:hover:border-white"
          />
        </button>
      </button>

      <div className="max-h-[600px] overflow-auto">
        {open &&
          gamesList.map((game, index) => (
            <div
              key={game.gameID}
              className="w-full flex flex-row items-center p-8"
              draggable="true"
              onDragStart={(e) => handleDragStart(e, game)}
              onDragEnd={handleDragEnd}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, game)}
            >
              <p className="mx-10 text-4xl w-16" draggable="false">
                {' '}
                {index + 1}
              </p>
              <div
                className="bg-admin dark:bg-admin-dark w-full rounded-lg"
                draggable="false"
              >
                <GamesCard
                  game={game}
                  favoriteGames={favoriteGames}
                  setFavoriteGames={setFavoriteGames}
                />
              </div>
              <img
                src="/drag.png"
                alt="drag"
                className="ml-10 h-12 cursor-pointer"
                draggable="false"
              />
              <button type="button" onClick={() => deleteFromList(game)}>
                <img
                  src="/bin.png"
                  alt="bin"
                  className="ml-10 h-12 cursor-pointer rounded-full border border-transparent hover:border-black dark:hover:border-white"
                  draggable="false"
                />
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}

List.propTypes = {
  list: PropTypes.shape({
    listID: PropTypes.string,
    title: PropTypes.string,
    games: PropTypes.arrayOf(
      PropTypes.shape({
        gameID: PropTypes.string,
        title: PropTypes.string,
        categories: PropTypes.arrayOf(PropTypes.string),
        rating: PropTypes.number,
        numberOfPeople: PropTypes.string,
      }),
    ),
  }).isRequired,
  favoriteGames: PropTypes.arrayOf(
    PropTypes.shape({
      gameID: PropTypes.string,
      title: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.string),
      rating: PropTypes.number,
      numberOfPeople: PropTypes.string,
    }),
  ).isRequired,
  setFavoriteGames: PropTypes.func.isRequired,
  onDeleteList: PropTypes.func.isRequired,
};

export default List;
