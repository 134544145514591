import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import databaseService from '../../services/DatabaseService';
import Plus from '../Icons/Plus';

function NewCategoryPopup({ closePopup, categories, setCategories }) {
  const [availableCategories, setAvailableCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  // Finds all categories
  useEffect(() => {
    databaseService.getAvailableCategories().then((_categories) => {
      setAvailableCategories(_categories);
      setAllCategories(_categories);
    });
  }, []);

  // Corrects available categories after removal/adding of category
  useEffect(() => {
    setAvailableCategories(
      allCategories.filter((item) => !categories.includes(item)),
    );
  }, [categories, allCategories]);

  function addCategory(category) {
    if (!categories.includes(category)) {
      setCategories([...categories, category]);
    }
  }

  function removeCategory(category) {
    if (categories.includes(category)) {
      setCategories(categories.filter((item) => item !== category));
    }
  }

  return (
    <section className="flex justify-center items-center h-full w-full">
      <div
        onClick={() => closePopup()}
        role="button"
        aria-label="Close"
        tabIndex={0}
        onKeyDown={(e) => e.key === 'Escape' && closePopup()}
        className="absolute w-full h-full bg-black/30"
      />
      <div className="relative rounded-xl bg-quaternary dark:bg-quaternary-dark h-[95%] w-[95%] sm:h-[80%] sm:w-[80%] text-center">
        <div className="absolute w-full flex justify-end">
          <button
            onClick={() => closePopup()}
            type="button"
            aria-label="Close"
            className="flex justify-center items-center hover:bg-[#d6c296]/50 m-4 w-[4rem] h-[4rem] rounded text-4xl"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-10 h-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="flex flex-col p-10 gap-8">
          <h1 className="font-bold text-3xl">Velg kategori</h1>
          <h2 className="text-left font-semibold text-2xl">
            Valgte kategorier
          </h2>
          <div className="flex flex-row flex-wrap gap-4">
            {categories.map((category) => (
              <button
                key={category}
                type="button"
                onClick={() => removeCategory(category)}
              >
                <div className="flex flex-row bg-category dark:bg-category-dark text-xl rounded-full px-4 py-2 gap-2 items-center">
                  <p>{category}</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="red"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </button>
            ))}
          </div>
          <div className="flex justify-center">
            <hr className="border w-[90%] border-black" />
          </div>
          <h2 className="text-left font-semibold text-2xl">
            Legg til kategorier
          </h2>
          <div className="flex flex-row flex-wrap gap-4">
            {availableCategories.map((category) => (
              <button
                key={category}
                type="button"
                onClick={() => addCategory(category)}
              >
                <div className="flex flex-row bg-category dark:bg-category-dark text-xl rounded-full px-4 py-2 gap-2 items-center">
                  <p>{category}</p>
                  <Plus size={6} />
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

NewCategoryPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  setCategories: PropTypes.func.isRequired,
};

export default NewCategoryPopup;
