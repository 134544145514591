import PropTypes from 'prop-types';
import React from 'react';

function AdCard({ adUrl }) {
  return (
    <div className="w-full h-full">
      <div className="flex flex-col h-full justify-center rounded-lg border p-12 text-center bg-gray-200 hover:border-black dark:hover:border-white dark:bg-adCard-dark dark:border-adCard-dark">
        {adUrl && (
          <img
            src={adUrl}
            alt="Reklamebilde"
            className="w-full h-full object-cover rounded-lg "
          />
        )}
      </div>
    </div>
  );
}

AdCard.propTypes = {
  adUrl: PropTypes.string.isRequired,
};

export default AdCard;
