import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import React, { useEffect, useState } from 'react';
import CommentClass from '../services/CommentClass';
import databaseService from '../services/DatabaseService';
import { auth } from '../config/firebase';

function CommentBox({ game, setComments, allComments }) {
  const [comment, setComment] = useState('');

  const handleChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const trimmedText = comment.trim();
    if (trimmedText === '') {
      toast.error('Kommentarfeltet kan ikke være tomt!');
      return;
    }
    const fullComment = new CommentClass(
      null,
      auth.currentUser.uid,
      auth.currentUser.displayName,
      auth.currentUser.email,
      window.location.pathname.substring(6),
      game.title,
      trimmedText,
      new Date(),
    );
    const commentID = await databaseService.addComment(fullComment);
    fullComment.ID = commentID;
    setComments([...allComments, fullComment]);
  };

  useEffect(() => {
    window.document.getElementById('comment').value = '';
    setComment('');
  }, [allComments]);

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col w-full p-5 bg-admin dark:bg-admin-dark rounded-lg"
    >
      <div className="flex items-center mb-2">
        <span className="mr-2">👤</span>
        Min bruker
      </div>
      <textarea
        id="comment"
        placeholder="Skriv din kommentar..."
        value={comment}
        onChange={handleChange}
        className="border border-commentBorder dark:border-commentBorder-dark rounded dark:placeholder-weakText-dark p-2 resize-none bg-quaternary dark:bg-quaternary-dark"
      />
      <input
        id="input"
        type="submit"
        value="Submit"
        className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
      />
    </form>
  );
}

CommentBox.propTypes = {
  game: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  setComments: PropTypes.func.isRequired,
  allComments: PropTypes.arrayOf(CommentClass).isRequired,
};

export default CommentBox;
