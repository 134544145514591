import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { auth } from '../../config/firebase';
import databaseService from '../../services/DatabaseService';

function AreYouSure({ gameIdToDelete, onDelete, closeAreYouSure }) {
  const [clicked, setClicked] = useState(false);

  async function handleYesClick() {
    const deleted = await databaseService.deleteGame(
      gameIdToDelete,
      auth.currentUser.uid,
    );
    if (deleted) {
      onDelete(gameIdToDelete); // Delete the game and update the list of games
    }
  }

  return (
    <div
      id="are-you-sure"
      className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-90"
    >
      <div className="rounded-lg p-8 m-4 text-center bg-blue-50 dark:bg-admin-dark">
        <p className="text-4xl mb-5">Er du sikker?</p>
        <div className="flex justify-center space-x-4">
          <button
            className="rounded-lg p-6 text-center w-2/4 bg-red-600 text-white"
            type="button"
            onClick={() => setClicked(false) || closeAreYouSure()}
          >
            Nei
          </button>
          {!clicked && (
            <button
              className="rounded-lg p-6 text-center w-2/4 bg-green-600 text-white"
              type="button"
              onClick={handleYesClick}
            >
              Ja
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

AreYouSure.propTypes = {
  gameIdToDelete: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  closeAreYouSure: PropTypes.func.isRequired,
};

export default AreYouSure;
