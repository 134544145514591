import React, { useState, useEffect } from 'react';

function Stopwatch() {
  // Hent tid og aktivitetsstatus fra localStorage når komponenten lastes
  const [startTime, setStartTime] = useState(
    Number(localStorage.getItem('stopwatchStartTime')) || Date.now(),
  );
  const [isActive, setIsActive] = useState(
    localStorage.getItem('stopwatchIsActive') === 'true',
  );
  const [elapsedTime, setElapsedTime] = useState(
    isActive
      ? Date.now() - startTime
      : Number(localStorage.getItem('stopwatchElapsedTime')) || 0,
  );

  useEffect(() => {
    let interval;
    if (isActive) {
      interval = setInterval(() => {
        setElapsedTime(Date.now() - startTime);
      }, 10);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, startTime]);

  // Lagre tid og aktivitetsstatus til localStorage når de endres
  useEffect(() => {
    localStorage.setItem('stopwatchStartTime', startTime.toString());
    localStorage.setItem('stopwatchIsActive', isActive.toString());
  }, [startTime, isActive]);

  const toggle = () => {
    if (isActive) {
      // Hvis vi ikke er aktive, lagrer vi starttidspunktet i tilfelle vi kommer tilbake
      localStorage.setItem('stopwatchElapsedTime', elapsedTime);
    } else {
      // Hvis vi er aktiv, endrer vi starttidspunktet til nå minus brukt tid
      setStartTime(Date.now() - elapsedTime);
    }
    setIsActive(!isActive);
  };

  const reset = () => {
    setElapsedTime(0);
    setIsActive(false);
    localStorage.removeItem('stopwatchElapsedTime');
    localStorage.removeItem('stopwatchIsActive');
  };

  const formatTime = (time) => {
    const milliseconds = `0${Math.floor(time / 10) % 100}`.slice(-2);
    const seconds = `0${Math.floor(time / 1000) % 60}`.slice(-2);
    const minutes = `0${Math.floor(time / 60000) % 60}`.slice(-2);
    return `${minutes}:${seconds}.${milliseconds}`;
  };

  return (
    <div className="flex flex-col items-center text-2xl">
      <code>{formatTime(elapsedTime)}</code>
      <div className="flex flex-row justify-center gap-2 text-lg">
        <button type="button" onClick={toggle} className="text-xl">
          {isActive ? '⏸' : '▶'}
        </button>
        <button type="button" onClick={reset} className="reset-btn text-xl">
          🔁
        </button>
      </div>
    </div>
  );
}

export default Stopwatch;
