import { getStorage, ref, getDownloadURL, listAll } from 'firebase/storage';

class StorageService {
  constructor() {
    this.storage = getStorage();
  }

  /**
   * Fetches the download URL for the specified image.
   *
   * @param {string} imageName - The name of the image.
   * @returns {Promise<string>} - A promise that resolves with the download URL of the image.
   */
  async fetchImageDownloadUrl(imageName) {
    const imageRef = ref(this.storage, `ads/${imageName}`);
    return getDownloadURL(imageRef);
  }

  /**
   * Fetches all image download URLs from the storage.
   * @async
   * @returns {Promise<string[]>} An array of image download URLs.
   * @throws {Error} If there is an error fetching the image download URLs.
   */
  async fetchAllImageDownloadUrls() {
    const adsRef = ref(this.storage, 'ads/');

    // List all items under 'ads/' directory
    const listResponse = await listAll(adsRef);

    // Map over each item in the listResponse to get their download URLs
    const downloadUrls = await Promise.all(
      listResponse.items.map((item) => getDownloadURL(item)),
    );

    return downloadUrls;
  }
}
export default new StorageService();
