import PropTypes from 'prop-types';
import React from 'react';
import BackButton from '../BackButton/BackButton';
import Plus from '../Icons/Plus';

function NewGameCard({
  setTitle,
  setNumberOfPlayers,
  categories,
  setCategories,
  setDescription,
  openPopup,
}) {
  const inputStyle =
    'rounded-xl border border-black p-2 dark:placeholder-weakText-dark bg-quaternary dark:bg-quaternary-dark';

  function removeCategory(category) {
    if (categories.includes(category)) {
      const currentCat = categories.filter((item) => item !== category);
      setCategories(currentCat);
    }
  }

  return (
    <section className="relative">
      <section className="flex flex-col  rounded-2xl bg-admin dark:bg-admin-dark w-full">
        <div className="ml-5 mt-5 ">
          <BackButton />
        </div>
        <div className="flex flex-col items-center rounded-2xl w-full py-20 gap-8">
          <input
            id="title"
            className={`${inputStyle} text-4xl mx-4 w-[80%] lg:w-[500px] text-center`}
            placeholder="Navn på lek..."
            onChange={(e) => setTitle(e.target.value)}
          />
          <div className="flex flex-row items-center gap-2">
            <input
              id="numberOfPeople"
              className={`${inputStyle} text-center max-w-[150px]`}
              placeholder="Antall spillere"
              onChange={(e) => setNumberOfPlayers(e.target.value)}
            />
            <img src="/people.png" alt="people" className="max-w-8" />
          </div>
          <div className="flex flex-row flex-wrap justify-center gap-4 max-w-[70%] text-md">
            {categories.map((category) => (
              <button
                key={category}
                type="button"
                onClick={() => removeCategory(category)}
              >
                <div className="flex flex-row gap-2 items-center bg-category dark:bg-category-dark rounded-full px-3">
                  <p>{category}</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="red"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </button>
            ))}
            <button type="button" onClick={() => openPopup()}>
              <div className="flex flex-row gap-2 items-center bg-category dark:bg-category-dark rounded-full px-3">
                <p>Legg til kategori</p>
                <Plus size={5} />
              </div>
            </button>
          </div>
          <textarea
            id="Description"
            className={`${inputStyle} lg:w-2/3 w-[80%] h-[300px]`}
            placeholder="Beskrivelse..."
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </section>
    </section>
  );
}

NewGameCard.propTypes = {
  setTitle: PropTypes.func.isRequired,
  setNumberOfPlayers: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  setCategories: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
};

export default NewGameCard;
