/**
 * Class representing a list of games.
 * @class

 * @example
 * const game1 = new GameClass(...);
 * const game2 = new GameClass(...);
 * const list = new ListClass(
 *   "listID",
 *   "userID",
 *   "List Title",
 *   [game1, game2, ...]
 * );
 *
 * @example
 * const list = (await databaseService.getLists(auth.currentUser.uid))[0];
 * list.title = "New Title"
 * list.games.push(new GameClass(...))
 * databaseService.updateList(auth.currentUser.uid, list);
 */
export default class ListClass {
  /**
   * @param {string} listID The ID of the list
   * @param {string} userID The ID of the owner of the list
   * @param {string} title The title of the game
   * @param {GameClass[]} games The games in the list
   */
  constructor(listID, userID, title, games = []) {
    this.listID = listID;
    this.userID = userID;
    this.title = title;
    this.games = games;
  }

  setGames(games) {
    this.games = games;
  }
}
