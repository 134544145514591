import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAYeC3gbKqbPe7Uw-Xv1IzsdN_bfQR5Uh8',
  authDomain: 'icebreaker-gruppe22.firebaseapp.com',
  projectId: 'icebreaker-gruppe22',
  messagingSenderId: '320764843096',
  appId: '1:320764843096:web:8c59bf9d142295d88be62d',
  measurementId: 'G-DNVN8TJESK',
  storageBucket: 'gs://icebreaker-gruppe22.appspot.com',
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
