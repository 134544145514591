import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function SpinTheWheel({ favoriteGames }) {
  const [rotation, setRotation] = useState(0);
  const [selectedGame, setSelectedGame] = useState(null);
  const [gameSegments, setGameSegments] = useState([]);

  useEffect(() => {
    // Mapper favorittspillene til segmenter på hjulet
    setGameSegments(favoriteGames);
  }, [favoriteGames]);

  const handleSpin = () => {
    const randomRotation = 3 * 360 + Math.ceil(Math.random() * 360);
    const newRotation = rotation + randomRotation;
    setRotation(newRotation);

    // Nullstiller etter valg av spill
    setSelectedGame(null);

    // Velger en tilfeldig vinner etter 1 sekund
    setTimeout(() => {
      const segmentAngle = 360 / gameSegments.length;
      const selectedGameIndex = Math.floor(
        ((newRotation + segmentAngle / 2) % 360) / segmentAngle,
      );
      setSelectedGame(gameSegments[selectedGameIndex]);
    }, 1000);
  };

  return (
    <div className="relative w-72">
      <div className="relative h-72">
        <svg
          className="absolute w-full h-full transform transition-transform duration-1000 ease-in-out"
          style={{ transform: `rotate(${rotation}deg)` }}
          viewBox="0 0 42 42"
        >
          {favoriteGames.map((game, index) => {
            const segmentAngle = 360 / favoriteGames.length;
            const startAngle = index * segmentAngle;
            const endAngle = (index + 1) * segmentAngle;
            const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
            const startX = 21 + 20 * Math.cos((Math.PI * startAngle) / 180);
            const startY = 21 + 20 * Math.sin((Math.PI * startAngle) / 180);
            const endX = 21 + 20 * Math.cos((Math.PI * endAngle) / 180);
            const endY = 21 + 20 * Math.sin((Math.PI * endAngle) / 180);
            const midAngle = (startAngle + endAngle) / 2;
            const textX = 21 + 10 * Math.cos((Math.PI * midAngle) / 180);
            const textY = 21 + 10 * Math.sin((Math.PI * midAngle) / 180);
            return (
              <g key={game.gameID}>
                <path
                  d={`M21 21 L${startX} ${startY} A20 20 0 ${largeArcFlag} 1 ${endX} ${endY} Z`}
                  fill={`hsl(${index * segmentAngle}, 100%, 50%)`}
                />
                <text
                  x={textX}
                  y={textY}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fill="white"
                  transform={`rotate(${midAngle - 90}, ${textX}, ${textY})`}
                  fontSize="3"
                >
                  {index + 1}
                </text>
              </g>
            );
          })}
        </svg>
        <button
          type="button"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 p-2 rounded-full bg-white dark:bg-[#1D334A] border-2 border-black"
          onClick={handleSpin}
        >
          Spin
        </button>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-2 h-6 bg-black dark:bg-white" />
      </div>
      <div className="flex flex-col h-full justify-center p-4 text-center relative m-3 text-xl font-semibold">
        Selected game:{' '}
        <Link
          to={selectedGame && `/game/${selectedGame.gameID}`}
          className={`inline-block mt-3 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded w-full h-full font-normal text-lg ${!selectedGame && 'invisible'}`}
        >
          {selectedGame ? selectedGame.title : 'invisible'}
        </Link>
      </div>
    </div>
  );
}

SpinTheWheel.propTypes = {
  favoriteGames: PropTypes.arrayOf(
    PropTypes.shape({
      gameID: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SpinTheWheel;
