import React, { useState, useEffect } from 'react';

function DarkMode() {
  const [darkmode, setDarkmode] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('mode') === 'dark') {
      setDarkmode(true);
    }
  }, []);

  function changeMode() {
    if (window.document.documentElement.classList.contains('dark')) {
      localStorage.setItem('mode', 'light');
      window.document.documentElement.classList.remove('dark');
      setDarkmode(false);
    } else {
      localStorage.setItem('mode', 'dark');
      window.document.documentElement.classList.add('dark');
      setDarkmode(true);
    }
  }

  return (
    <section className="flex flex-col gap-2 justify-center items-center">
      <button
        onClick={() => changeMode()}
        type="button"
        className={`
                    flex items-center rounded-full border-2 border-gray-600
                    ${darkmode ? 'bg-black border-black pl-8' : 'bg-white pr-8'}
                    ease-out duration-500
                `}
      >
        {/* The circle in the middle */}
        <div
          className={`
                    flex justify-center items-center p-1 w-[30px] h-[30px] mx-1 my-1 rounded-full
                    ${darkmode ? 'bg-white' : 'bg-black'}
                    ease-out duration-500
                `}
        >
          {darkmode ? (
            // Sun icon
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="black"
              className="w-full h-full"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
              />
            </svg>
          ) : (
            // Moon icon
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="white"
              className="w-full h-full"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
              />
            </svg>
          )}
        </div>
      </button>
    </section>
  );
}

export default DarkMode;
