import PropTypes from 'prop-types';
import React from 'react';

/**
 * Plus icon
 *
 * @param {Object} props
 * @param {number} props.size The size of the component
 *
 * @example
 * <Plus size={8}/>
 */
function Plus({ size = 8 }) {
  const sizeInPx = `${4 * size}px`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="3"
      stroke="currentColor"
      style={{ width: `${sizeInPx}`, height: `${sizeInPx}` }}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>
  );
}

Plus.defaultProps = {
  size: 8,
};

Plus.propTypes = {
  size: PropTypes.number,
};

export default Plus;
