import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebase';

export default function SignupPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const validateName = () => {
    if (name.trim() === '') {
      setErrorMessage('Vennligst skriv ditt navn');
      return false;
    }
    return true;
  };

  const validatePassword = () => {
    if (password.length < 6) {
      setErrorMessage('Passord må være minst 6 tegn langt.');
      return false;
    }

    if (password !== passwordConfirm) {
      setErrorMessage('Passord matcher ikke.');
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const signup = async () => {
    if (!validatePassword() || !validateName()) {
      toast.error('Passord validering feilet');
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      await updateProfile(userCredential.user, { displayName: name });
      navigate('/');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setErrorMessage(
          'Email adressen du skrev er allerede i bruk, vennligs velg en annen',
        );
      } else {
        toast.error('Feil ved registrering');
      }
    }
  };

  return (
    <div className="relative flex flex-col items-center justify-center p-10 bg-white shadow-lg rounded-lg w-full max-w-xl m-auto my-12">
      <button
        type="button"
        onClick={() => navigate(-1)} // This will navigate back to the previous page
        className="absolute top-4 left-4 bg-no-repeat bg-center bg-contain w-10 h-10"
      >
        <img src="/backward.png" alt="Tilbake" />
      </button>
      <h1 className="text-2xl text-gray-800 font-semibold mb-12">Lag Bruker</h1>
      <input
        className="w-full p-4 mb-6 border border-gray-300 rounded-full text-gray-900 placeholder-gray-400 focus:border-gray-400 focus:ring-4 focus:ring-blue-200 outline-none"
        placeholder="Navn"
        onChange={(e) => setName(e.target.value)}
      />
      <input
        className="w-full p-4 mb-6 border border-gray-300 rounded-full text-gray-900 placeholder-gray-400 focus:border-gray-400 focus:ring-4 focus:ring-blue-200 outline-none"
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        className="w-full p-4 mb-6 border border-gray-300 rounded-full text-gray-900 placeholder-gray-400 focus:border-gray-400 focus:ring-4 focus:ring-blue-200 outline-none"
        type="password"
        placeholder="Passord"
        onChange={(e) => setPassword(e.target.value)}
      />
      <input
        className="w-full p-4 mb-6 border border-gray-300 rounded-full text-gray-900 placeholder-gray-400 focus:border-gray-400 focus:ring-4 focus:ring-blue-200 outline-none"
        type="password"
        placeholder="Bekreft passord"
        onChange={(e) => setPasswordConfirm(e.target.value)}
      />
      <p className="text-red-500 mb-6">{errorMessage}</p>
      <button
        type="submit"
        className="w-full p-4 bg-gray-700 text-white rounded-full cursor-pointer hover:bg-gray-800 focus:outline-none transition-colors"
        onClick={signup}
      >
        Register
      </button>
    </div>
  );
}
