import PropTypes from 'prop-types';
import React from 'react';

/**
 * Checkmark icon
 *
 * @param {Object} props
 * @param {number} props.size The size of the component
 *
 * @example
 * <Checkmark size={8}/>
 */
function Checkmark({ size = 8 }) {
  const sizeInPx = `${4 * size}px`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="3"
      stroke="currentColor"
      style={{ width: `${sizeInPx}`, height: `${sizeInPx}` }}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L12 20L22 7" />
    </svg>
  );
}

Checkmark.defaultProps = {
  size: 8,
};

Checkmark.propTypes = {
  size: PropTypes.number,
};

export default Checkmark;
