import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { auth, googleProvider } from '../config/firebase';

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const signIn = async (e) => {
    try {
      e.preventDefault();
      setErrorMessage('');
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      if (error.code === 'auth/invalid-credential') {
        setErrorMessage('Feil E-post eller passord');
        // alert('No user found with this email. Please sign up.');

        // navigate('/signup'); Direkte navigering til signup?
      } else {
        setErrorMessage('Feil E-Post eller passord');
        // alert('Error logging in: ' + error.message);
      }
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate('/');
    } catch (error) {
      toast.error('Feil ved innlogging med Google');
    }
  };

  // const logOut = async () => {
  //     try {
  //         await signOut(auth);
  //     } catch (error) {
  //         console.log(error);
  //     }
  // };

  return (
    <div className="flex flex-col items-center justify-center p-10 bg-white shadow-lg rounded-lg w-full max-w-xl m-auto my-12">
      <h1 className="text-2xl text-gray-800 font-semibold mb-12">IceBreaker</h1>
      <form className="w-full" onSubmit={signIn}>
        <input
          className="w-full p-4 mb-6 border border-gray-300 rounded-full text-gray-900 placeholder-gray-400 focus:border-gray-400 focus:ring-4 focus:ring-blue-200 outline-none"
          placeholder="E-post"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="w-full p-4 mb-6 border border-gray-300 rounded-full text-gray-900 placeholder-gray-400 focus:border-gray-400 focus:ring-4 focus:ring-blue-200 outline-none"
          type="password"
          placeholder="Passord"
          onChange={(e) => setPassword(e.target.value)}
        />
        <p className="text-red-500 mb-6">{errorMessage}</p>
        <button
          type="submit"
          className="w-full p-4 mb-6 bg-gray-700 text-white rounded-full cursor-pointer hover:bg-gray-800 focus:outline-none transition-colors"
        >
          Logg inn
        </button>
      </form>
      <button
        type="button"
        className="w-full p-4 mb-6 bg-gray-600 text-white rounded-full cursor-pointer hover:bg-gray-700 focus:outline-none transition-colors"
        onClick={signInWithGoogle}
      >
        Logg Inn Med Google
      </button>
      <Link
        to="/signup"
        className="mt-2 text-blue-700 hover:text-blue-800 transition-colors cursor-pointer hover:underline"
      >
        Har du ikke bruker? Registrer deg her
      </Link>
    </div>
  );
}
