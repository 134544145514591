import { signOut } from 'firebase/auth';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { auth } from '../../config/firebase';
import databaseService from '../../services/DatabaseService';
import DarkMode from '../DarkMode/DarkMode';
import FilterPanel from './FilterPanel';

function Navbar({ onSearch, onFiltersChange }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilters, setActiveFilters] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  databaseService
    .isAdmin(auth.currentUser.uid)
    .then((value) => setIsAdmin(value));

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    onSearch(term); // Kall onSearch-propen med den oppdaterte søketeksten
  };

  /**
   * Håndterer valg av filter ved å oppdatere activeFilters-state og varsler parent-component om endirngen
   * */
  const handleFilterSelect = (filterName) => {
    let updatedFilters;
    if (activeFilters.includes(filterName)) {
      updatedFilters = activeFilters.filter((f) => f !== filterName);
    } else {
      updatedFilters = [...activeFilters, filterName];
    }
    setActiveFilters(updatedFilters);

    if (onFiltersChange) {
      onFiltersChange(updatedFilters);
    }
  };

  async function logOut() {
    try {
      await signOut(auth);
      window.location.href = '/';
    } catch (error) {
      toast.error('Kunne ikke logge ut');
    }
  }

  return (
    <div className="flex flex-col gap-8 w-66 bg-gray-800 text-white p-4 h-full">
      <Link
        to="/"
        className="rounded-md bg-gray-700 px-6 py-6 mb-5 text-center flex justify-center font-bold text-2xl"
      >
        Ice Breakers
      </Link>

      <Link to="/profile" className="block hover:underline text-lg px-3">
        Min profil
      </Link>
      <Link to="/mylists" className="block hover:underline text-lg px-3">
        Mine lister
      </Link>
      {isAdmin ? (
        <Link to="/admin" className="block mb-2 hover:underline text-lg px-3">
          Admin
        </Link>
      ) : (
        false
      )}

      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        className="w-full bg-gray-700 text-white rounded-full px-4 py-2 focus:outline-none focus:bg-gray-900"
        placeholder="Søk..."
      />

      <FilterPanel onFilterSelect={handleFilterSelect} />

      <div className="flex flex-col items-start fixed bottom-3 left-5">
        <div className="flex flex-row gap-2 mb-20">
          <p>Kontakt oss:</p>
          <p>
            <a
              href="mailto: produkteier@ntnu.no"
              className="text-blue-500 hover:underline"
            >
              E-post
            </a>
          </p>
        </div>
        <section className="flex flex-row gap-2 fixed bottom-10 left-5">
          <DarkMode />
          <button
            onClick={logOut}
            type="button"
            className="bg-[#7395cd] hover:bg-[#6483bb] text-white font-bold px-10 rounded-full"
          >
            Logg ut
          </button>
        </section>
      </div>
    </div>
  );
}

Navbar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
};

export default Navbar;
