import React, { useState } from 'react';
import toast from 'react-hot-toast';
import Plus from '../../components/Icons/Plus';
import NewCategoryPopup from '../../components/NewGame/NewCategoryPopup';
import NewGameCard from '../../components/NewGame/NewGameCard';
import { auth } from '../../config/firebase';
import databaseService from '../../services/DatabaseService';
import GameClass from '../../services/GameClass';

function NewGame() {
  const [title, setTitle] = useState('');
  const [numberOfPeople, setNumberOfPeople] = useState('');
  const [categories, setCategories] = useState([]);
  const [description, setDescription] = useState('');

  const [popupDisplay, setPopupDisplay] = useState({ display: 'none' });

  function createGame() {
    if (title !== '' && numberOfPeople !== '' && description !== '') {
      const newGame = new GameClass(
        null,
        title,
        description,
        numberOfPeople,
        0,
        categories,
        auth.currentUser.uid,
      );
      databaseService.addGame(newGame).then((gameID) => {
        window.open(`/game/${gameID}`, '_self');
      });
    } else {
      toast.error('Alle feltene må fylles inn');
    }
  }

  function closePopup() {
    setPopupDisplay({ display: 'none' });
  }

  function openPopup() {
    setPopupDisplay({ display: 'block' });
  }

  return (
    <section className="flex flex-col gap-4 m-4 pb-5">
      <div style={popupDisplay} className="fixed z-10 inset-0 h-full w-full">
        <NewCategoryPopup
          closePopup={() => closePopup()}
          categories={categories}
          setCategories={setCategories}
        />
      </div>
      <section className="flex flex-col width">
        <div className="mb-5">
          <NewGameCard
            setTitle={setTitle}
            setNumberOfPlayers={setNumberOfPeople}
            categories={categories}
            setCategories={setCategories}
            setDescription={setDescription}
            openPopup={() => openPopup()}
          />
        </div>
        <button
          type="button"
          onClick={() => createGame()}
          className="flex flex-row items-center justify-center
                        border border-transparent hover:border-black dark:hover:border-white
                        gap-4 md:py-8 md:w-full p-4 rounded-xl
                        bg-admin dark:bg-admin-dark"
        >
          <p className="text-2xl md:text-3xl">Lag spill</p>
          <Plus size={10} />
        </button>
      </section>
    </section>
  );
}

export default NewGame;
