import PropTypes from 'prop-types';
import React from 'react';

function ReportCard({ report, deleteReport }) {
  if (!report) {
    return null;
  }

  function handleDeleteReport(event) {
    event.preventDefault();
    if (report.ID) {
      deleteReport(report.ID);
    }
  }

  return (
    <div className="rounded-lg border border-transparent p-7 text-left relative hover:border-black dark:hover:border-white">
      <div className="report-info">
        <div className="flex justify-between">
          <div className="report-description bg-reportDescription dark:bg-reportDescription-dark p-3 mt-2 rounded-lg flex-grow mr-5">
            <p className="text-gray-800 dark:text-weakText-dark whitespace-pre-line">
              <strong>Begrunnelse</strong> <br />
              {report.text}
            </p>
          </div>
          <div className="flex flex-col justify-around">
            <div className="text-weakText dark:text-weakText-dark">
              {report.timestampAsString}
            </div>
            <div className="text-left">
              <button
                className="underline"
                type="button"
                onClick={handleDeleteReport}
              >
                Slett rapportering
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ReportCard.propTypes = {
  report: PropTypes.shape({
    ID: PropTypes.string,
    text: PropTypes.string,
    timestampAsString: PropTypes.string,
  }).isRequired,
  deleteReport: PropTypes.func.isRequired,
};

export default ReportCard;
