/* eslint-disable class-methods-use-this */
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import GameClass from './GameClass';
import CommentClass from './CommentClass';
import ListClass from './ListClass';
import RatingClass from './RatingClass';

/**
 * A class which provides methods to easily interact with firebase.
 */
class DatabaseService {
  /**
   * Retrieves all games from the database as a list.
   *
   * @returns {Promise<GameClass[]>} A list of all games in the database.
   * @example
   * const games = await databaseService.getAllGames();
   * console.log(games); // [GameClass, GameClass, ...]
   */
  async getAllGames() {
    const games = [];
    const documents = await getDocs(collection(db, 'game'));
    documents.forEach((document) => {
      games.push(
        new GameClass(
          document.id,
          document.data().title,
          document.data().description,
          document.data().numberOfPeople,
          document.data().rating,
          document.data().categories,
          document.data().creatorID,
        ),
      );
    });
    return games;
  }

  /**
   * Adds a game to the database.
   *
   * @param {GameClass} game The game object to be added.
   * @returns {Promise<string>} The document ID of the added game (in firebase).
   *
   * @example
   * const game = new GameClass(...);
   * const documentID = await databaseService.addGame(game);
   * console.log(documentID); // "documentID"
   */
  async addGame(game) {
    const docRef = await addDoc(collection(db, 'game'), {
      title: game.title,
      description: game.description,
      numberOfPeople: game.numberOfPeople,
      rating: game.rating,
      categories: game.categories,
      creatorID: game.creatorID,
      favoritedUsers: [],
    });
    return docRef.id;
  }

  /**
   * Retrieves a game by its ID from the database.
   *
   * @param {string} gameId The ID of the game to retrieve.
   * @returns {Promise<GameClass|null>} A GameClass instance if the game exists, or null if it doesn't.
   *
   * @example
   * const gameId = "gameId";
   * const game = await databaseService.getGameById(gameId);
   * console.log(game); // GameClass
   * console.log(game.title); // "Game Title"
   */
  async getGameById(gameId) {
    const q = doc(collection(db, 'game'), gameId);
    const document = await getDoc(q);
    if (document.exists()) {
      return new GameClass(
        document.id,
        document.data().title,
        document.data().description,
        document.data().numberOfPeople,
        document.data().rating,
        document.data().categories,
        document.data().creatorID,
      );
    }
    return null;
  }

  /**
   * Retrieves the available categories from the database.
   *
   * @returns {Promise<string[]>} An array of available categories, or null if the document containing
   *                     the categories does not exist on firebase.
   *
   * @example
   * const categories = await databaseService.getAvailableCategories();
   * console.log(categories); // ["category1", "category2", ...]
   */
  async getAvailableCategories() {
    const q = doc(collection(db, 'categories'), 'categories');
    const document = await getDoc(q);
    if (!document.exists()) {
      return null;
    }

    return document.data().available;
  }

  /**
   * Retrieves games that match all the specified categories.
   *
   * @param {string[]} categories An array of category names.
   * @returns {Promise<GameClass[]>} A list of games that match all the specified categories.
   *
   * @example
   * const categories = ["category1", "category2"];
   * const games = await databaseService.getGamesByCategories(categories);
   * console.log(games); // [GameClass, GameClass, ...]
   * console.log(games[0].categories); // ["category1", "category2", ...]
   */
  async getGamesByCategories(categories) {
    const games = await this.getAllGames();

    return games.filter((game) => {
      return categories.every((category) => game.categories.includes(category));
    });
  }

  /**
   * Retrieves games by creator ID.
   *
   * @param {string} creatorID The ID of the game creator.
   * @returns {Promise<GameClass[]>} An array of games.
   *
   * @example
   * const creatorID = "creatorID";
   * const games = await databaseService.getGamesByCreatorID(creatorID);
   * console.log(games); // [GameClass, GameClass, ...]
   * console.log(games[0].gameID); // "gameID"
   */
  async getGamesByCreatorID(creatorID) {
    const games = [];
    const q = query(
      collection(db, 'game'),
      where('creatorID', '==', creatorID),
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((document) => {
      games.push(
        new GameClass(
          document.id,
          document.data().title,
          document.data().description,
          document.data().numberOfPeople,
          document.data().rating,
          document.data().categories,
          document.data().creatorID,
        ),
      );
    });
    return games;
  }

  /**
   * Checks if a user is an admin.
   *
   * @param {string} userID The ID of the user to check.
   * @returns {Promise<boolean>} true if the user is an admin, false otherwise.
   *
   * @example
   * const userID = "userID";
   * const result = await databaseService.isAdmin(userID);
   * console.log(result); // true
   */
  async isAdmin(userID) {
    const q = query(
      collection(db, 'admin'),
      where('list', 'array-contains', userID),
    );
    const documents = await getDocs(q);
    return !documents.empty;
  }

  /**
   * Deletes reports associated with a game ID.
   *
   * This method is private and should only be called from within the class.
   *
   * @private
   * @param {string} gameID The ID of the game.
   * @returns {Promise<boolean>} False if an error occurs, true otherwise.
   *
   * @example
   * const gameID = "gameID";
   * const result = await databaseService.#deleteReports(gameID);
   * console.log(result); // true
   */
  async #deleteReports(gameID) {
    const q = query(collection(db, 'report'), where('gameID', '==', gameID));

    const querySnapshot = await getDocs(q);
    const batch = writeBatch(db);
    querySnapshot.forEach((document) => {
      batch.delete(document.ref);
    });

    try {
      await batch.commit();
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * Deletes comments associated with a game ID.
   *
   * This method is private and should only be called from within the class.
   *
   * @private
   * @param {string} gameID The ID of the game.
   * @returns {Promise<boolean>} False if an error occurs, true otherwise.
   *
   * @example
   * const gameID = "gameID";
   * const result = await databaseService.#deleteComments(gameID);
   * console.log(result); // true
   */
  async #deleteComments(gameID) {
    const q = query(collection(db, 'comment'), where('gameID', '==', gameID));

    const querySnapshot = await getDocs(q);
    const batch = writeBatch(db);
    querySnapshot.forEach((document) => {
      batch.delete(document.ref);
    });

    try {
      await batch.commit();
    } catch (e) {
      return false;
    }
    return true;
  }

  async #deleteRating(gameID) {
    const q = query(collection(db, 'rating'), where('gameID', '==', gameID));

    const querySnapshot = await getDocs(q);
    const batch = writeBatch(db);
    querySnapshot.forEach((document) => {
      batch.delete(document.ref);
    });

    try {
      await batch.commit();
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * Deletes a game from all lists that contain it.
   *
   * This method is private and should only be called from within the class.
   * @private
   *
   * @param {string} gameID The ID of the game to be deleted
   * @returns {Promise<boolean>} A promise that resolves to true if the game is successfully deleted from all lists, or false if an error occurs
   *
   * @example
   * const gameID = "gameID";
   * Promise.all([
   *   this.#deleteReports(gameID),
   *   this.#deleteComments(gameID),
   *   this.#deleteRating(gameID),
   *   this.#deleteGameFromLists(gameID),
   * ]);
   */
  async #deleteGameFromLists(gameID) {
    const q = query(
      collection(db, 'list'),
      where('gameIDs', 'array-contains', gameID),
    );
    const querySnapshot = await getDocs(q);
    const batch = writeBatch(db);
    querySnapshot.forEach((document) => {
      batch.update(document.ref, {
        gameIDs: arrayRemove(gameID),
      });
    });

    try {
      await batch.commit();
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * Deletes a game from the database. This method also deletes all reports associated with the game.
   *
   * @param {string} gameID The ID of the game to be deleted.
   * @param {string} deleterUserID The ID of the user deleting the game.
   * @returns {Promise<boolean>} A true/false indicating if the game was deleted.
   *
   * @example
   * const gameID = "gameID";
   * const deleterUserID = "deleterUserID";
   * const result = await databaseService.deleteGame(gameID, deleterUserID);
   * console.log(result); // true
   */
  async deleteGame(gameID, deleterUserID) {
    // Returns false if user is not allowed to delete
    const allowed = await this.isAdmin(deleterUserID);
    if (!allowed) {
      return false;
    }
    const docRef = doc(db, 'game', gameID);

    Promise.all([
      deleteDoc(docRef),
      this.#deleteReports(gameID),
      this.#deleteComments(gameID),
      this.#deleteRating(gameID),
      this.#deleteGameFromLists(gameID),
    ]);

    return true;
  }

  /**
   * Adds a comment to the database.
   *
   * @param {CommentClass} comment - The comment to be added to the database.
   * @returns {Promise<string>} The document ID of the newly added comment (in firebase).
   *
   * @example
   * const comment = new CommentClass(...);
   * const documentID = await databaseService.addComment(comment);
   * console.log(documentID); // "documentID"
   */
  async addComment(comment) {
    const docRef = await addDoc(collection(db, 'comment'), {
      creatorID: comment.creatorID,
      creatorName: comment.creatorName,
      creatorEmail: comment.creatorEmail,
      gameID: comment.gameID,
      gameName: comment.gameName,
      text: comment.text,
      timestamp: comment.timestamp,
      edited: comment.edited,
    });
    return docRef.id;
  }

  /**
   * Deletes a comment from Firebase, if the deleter is the creator of the comment.
   *
   * @param {string} commentID The ID of the comment to delete.
   * @param {string} deleterUserID The ID of the user deleting the comment.
   * @returns {Promise<boolean>} True if the comment was deleted, false otherwise.
   *
   * @example
   * const commentID = await databaseService.addComment(new CommentClass(...));
   * const userID = auth.currentUser.uid;
   * const result = await databaseService.deleteComment(commentID, userID);
   * console.log(result); // true
   *
   * @example
   * // This is an example how how it can be used in CommentDisplay.js
   * const handleDeleteClick = (index, comment) => {
   *   databaseService
   *     .deleteComment(comment.ID, auth.currentUser.uid)
   *     .then((deleted) => {
   *       if (deleted) {
   *         removeComment(index);
   *       } else {
   *         alert("Kunne ikke slette kommentar");
   *       }
   *     });
   * };
   */
  async deleteComment(commentID, deleterUserID) {
    const docRef = doc(collection(db, 'comment'), commentID);
    const document = await getDoc(docRef);

    if (!document.data()) {
      return false;
    }

    if (document.data().creatorID !== deleterUserID) {
      return false;
    }

    await deleteDoc(docRef);
    return true;
  }

  /**
   * Retrieves comments by gameID.
   *
   * @param {string} gameID The ID of the game.
   * @returns {Promise<CommentClass[]>} An array of comments, sorted by oldest first.
   *
   * @example
   * const gameID = "gameID";
   * const comments = await databaseService.getCommentsByGameID(gameID);
   * console.log(comments) // [CommentClass, CommentClass, ...]
   * console.log(comments[0].text) // "Comment text"
   *
   * @example
   * const [comments, setComments] = useState([]);
   * const gameID = "gameID";
   * useEffect(() => {
   *     databaseService.getCommentsByGameID(gameID).then((comments) => {
   *         setComments(comments);
   *     });
   * }, [gameID]);
   */
  async getCommentsByGameID(gameID) {
    const comments = [];
    const q = query(collection(db, 'comment'), where('gameID', '==', gameID));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((document) => {
      comments.push(
        new CommentClass(
          document.id,
          document.data().creatorID,
          document.data().creatorName,
          document.data().creatorEmail,
          document.data().gameID,
          document.data().gameName,
          document.data().text,
          document.data().timestamp.toDate(),
          document.data().edited,
        ),
      );
    });

    return comments.sort((a, b) => a.timestamp - b.timestamp);
  }

  /**
   * Retrieves comments by userID.
   *
   * @param {string} userID The ID of the user.
   * @returns {Promise<CommentClass[]>} An array of comments, sortet by oldest first.
   *
   * @example
   * const userID = auth.currentUser.uid;
   * const comments = await databaseService.getCommentsByUserID(userID);
   * console.log(comments) // [CommentClass, CommentClass, ...]
   * console.log(comments[0].text) // "Comment text"
   */
  async getCommentsByUserID(userID) {
    const comments = [];
    const q = query(
      collection(db, 'comment'),
      where('creatorID', '==', userID),
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((document) => {
      comments.push(
        new CommentClass(
          document.id,
          document.data().creatorID,
          document.data().creatorName,
          document.data().creatorEmail,
          document.data().gameID,
          document.data().gameName,
          document.data().text,
          document.data().timestamp.toDate(),
          document.data().edited,
        ),
      );
    });

    return comments.sort((a, b) => a.timestamp - b.timestamp);
  }

  /**
   * Retrieves ratings by userID.
   *
   * This method fetches all ratings associated with a given user ID from the database.
   * Each rating is encapsulated within a RatingClass instance, which includes details
   * such as the rating ID, user ID, game ID, game name, and the rating value itself.
   * The resulting array of RatingClass instances is then returned to the caller.
   *
   * @param {string} userID The ID of the user for whom ratings are being retrieved.
   * @returns {Promise<RatingClass[]>} A promise that resolves with an array of RatingClass instances representing the ratings made by the specified user.
   *
   * @example
   * const userID = auth.currentUser.uid;
   * databaseService.getRatingByUserID(userID)
   *   .then(ratings => {
   *     console.log(ratings); // [RatingClass, RatingClass, ...]
   *     console.log(ratings[0].ratingValue); // Numeric rating value, e.g., 5
   *   })
   *   .catch(error => console.error(error));
   */
  async getRatingByUserID(userID) {
    const rating = [];
    const q = query(collection(db, 'rating'), where('userID', '==', userID));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((document) => {
      rating.push(
        new RatingClass(
          document.data().ratingID,
          document.data().userID,
          document.data().gameID,
          document.data().gameName,
          document.data().ratingValue,
        ),
      );
    });
    return rating;
  }

  /**
   * Updates a comment in the database.
   *
   * @param {string} userID The ID of the user performing the update.
   * @param {string} commentID The ID of the comment to be updated.
   * @param {string} newText The new text for the comment.
   * @returns {Promise<boolean>} True if the comment was updated, false otherwise.
   *
   * @example
   * const commentID = await databaseService.addComment(new CommentClass(...));
   * const commment = await databaseService.getCommentByID(commentID);
   * const userID = auth.currentUser.uid;
   * const newText = "New comment text";
   *
   * console.log(comment.edited); // false
   * const result = await databaseService.updateComment(userID, commentID, newText);
   * console.log(result); // true
   * console.log(comment.edited); // true
   */
  async updateComment(userID, commentID, newText) {
    const q = doc(collection(db, 'comment'), commentID);
    const document = await getDoc(q);
    if (document.data().creatorID === userID) {
      await updateDoc(q, { text: newText, edited: true });
      return true;
    }
    return false;
  }

  /**
   * Deletes a report from Firebase, without deleting the game.
   *
   * @param {string} reportID The ID of the report to delete.
   * @param {string} deleterUserID The ID of the user deleting the report.
   *
   * @returns {Promise<boolean>} False if the user is not allowed to delete reports, true otherwise.
   *
   * @example
   * import { auth } from '../config/firebase';
   *
   * const reportID = await databaseService.sendReport(...);
   *
   * // User is allowed to delete reports
   * const response = await databaseService.deleteReport(
   *    reportID,
   *    auth.currentUser.uid
   * );
   * console.log(response); // true
   *
   * @example
   * // User is not allowed to delete reports
   * const response = await databaseService.deleteReport(
   *   reportID,
   *   "invalidUserID"
   * );
   * console.log(response); // false
   */
  async deleteReport(reportID, deleterUserID) {
    const allowed = await this.isAdmin(deleterUserID);
    if (!allowed) {
      return false;
    }
    const docRef = doc(db, 'report', reportID);
    await deleteDoc(docRef);
    return true;
  }

  /**
   * Returns the games that the user has favorited
   * @param {string} userID
   * @returns {Promise<Array<GameClass>>}
   */
  async getFavoritedGames(userID) {
    const games = [];
    const q = query(
      collection(db, 'game'),
      where('favoritedUsers', 'array-contains', userID),
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((document) => {
      games.push(
        new GameClass(
          document.id,
          document.data().title,
          document.data().description,
          document.data().numberOfPeople,
          document.data().rating,
          document.data().categories,
          document.data().creatorID,
        ),
      );
    });
    return games;
  }

  /**
   * Adds or removes a game from the user's favorites
   * @param {string} gameID
   * @returns {Promise<boolean>} true if the game was added to favorites, false if it was removed
   */
  async addGameToFavorite(gameID) {
    const userID = auth.currentUser.uid;
    const q = doc(collection(db, 'game'), gameID);
    const document = await getDoc(q);
    if (!document.data().favoritedUsers.includes(userID)) {
      await updateDoc(q, { favoritedUsers: arrayUnion(userID) });
      return true;
    }
    await updateDoc(q, { favoritedUsers: arrayRemove(userID) });
    return false;
  }

  /**
   * Sends a report to the database connected to a game with a comment from a user.
   *
   * @param {CommentClass} report The report to be added to the database.
   *
   * @returns {Promise<string>} The ID of the report
   *
   * @example
   * const report = new CommentClass(...);
   * const documentID = await databaseService.sendReport(report);
   * console.log(documentID); // "reportID"
   */
  async sendReport(report) {
    const docRef = await addDoc(collection(db, 'report'), {
      creatorID: report.creatorID,
      creatorName: report.creatorName,
      creatorEmail: report.creatorEmail,
      gameID: report.gameID,
      gameName: report.gameName,
      text: report.text,
      timestamp: report.timestamp,
    });
    return docRef.id;
  }

  /**
   * Gets all reports to all games, batched by gameID. This is useful for displaying all reports to each game individually.
   *
   * @returns {Promise<Object.<string, CommentClass[]>>} A dictionary of gameID's to a list of reports.
   *
   * @example
   * const reports = await databaseService.getAllReportsBatched();
   * console.log(reports); // {
   *                       //   "gameID1": [CommentClass, CommentClass, ...],
   *                       //   "gameID2": [CommentClass, CommentClass, ...],
   *                       //   ...
   *                       //}
   *
   * @example
   * const [reports, setReports] = useState({});
   * useEffect(() => {
   *   databaseService.getAllReportsBatched().then((reports) => {
   *     setReports(reports);
   *   });
   * }, []);
   *
   * return (
   *   <div>
   *     {Object.keys(reports).map((gameID) => (
   *       <div key={gameID}>
   *         <h1>{reports[gameID][0].gameName}</h1>
   *         {reports[gameID].map((report) => (
   *           <div key={report.commentID}>
   *             <p>{report.text}</p>
   *           </div>
   *         ))}
   *       </div>
   *     ))}
   *   </div>
   * );
   */
  async getAllReportsBatched() {
    const reportsDict = {};

    const reportsList = await this.getAllReports();
    reportsList.forEach((report) => {
      if (!reportsDict[report.gameID]) {
        reportsDict[report.gameID] = [];
      }
      reportsDict[report.gameID].push(report);
    });

    return reportsDict;
  }

  /**
   * Gets all reports to all games as a list.
   *
   * @returns {Promise<CommentClass[]>} A list of all reports
   *
   * @example
   * const reports = await databaseService.getAllReports();
   * console.log(reports); // [CommentClass, ...]
   */
  async getAllReports() {
    const reports = [];

    const documents = await getDocs(collection(db, 'report'));
    documents.forEach((document) => {
      reports.push(
        new CommentClass(
          document.id,
          document.data().creatorID,
          document.data().creatorName,
          document.data().creatorEmail,
          document.data().gameID,
          document.data().gameName,
          document.data().text,
          document.data().timestamp.toDate(),
        ),
      );
    });
    return reports;
  }

  /**
   * Adds or edits a rating to a game.
   *
   * If the user has already rated the game, the rating will edited.
   * Otherwise, it is added.
   *
   * @param {RatingClass} rating
   * @returns {Promise<string>} The document ID of the added/edited rating (in firebase).
   *
   * @example
   * const rating = new RatingClass(...);
   * const docRefId = await databaseService.addRating(rating);
   * console.log(docRefId); // "Vj9JxHksYnFRT58n7ZdR"
   *
   */
  async addRating(rating) {
    const q = query(
      collection(db, 'rating'),
      where('userID', '==', rating.userID),
      where('gameID', '==', rating.gameID),
    );
    const querySnapshot = await getDocs(q);
    let docRef = null;
    if (querySnapshot.empty) {
      docRef = await addDoc(collection(db, 'rating'), {
        userID: rating.userID,
        gameID: rating.gameID,
        gameName: rating.gameName,
        ratingValue: rating.ratingValue,
      });
    } else {
      docRef = doc(db, 'rating', querySnapshot.docs[0].id);
      await updateDoc(docRef, {
        ratingValue: rating.ratingValue,
      });
    }
    await this.#updateTotalGameRating(rating.gameID);
    return docRef.id;
  }

  /**
   * Updates total game rating of one game.
   *
   * @private
   * @param {string} gameID
   *
   * @example
   * const game = new GameClass(...);
   * await databaseService.#updateTotalGameRating(gameID);
   */
  async #updateTotalGameRating(gameID) {
    const q = query(collection(db, 'rating'), where('gameID', '==', gameID));
    const querySnapshot = await getDocs(q);
    let ratings = 0;
    const nrOfRatings = querySnapshot.size;
    querySnapshot.forEach((rating) => {
      ratings += rating.data().ratingValue;
    });
    const averageRating = (ratings / nrOfRatings).toFixed(2);
    const docRef = doc(db, 'game', gameID);

    const document = await getDoc(docRef);
    if (document.exists()) {
      await updateDoc(docRef, {
        rating: averageRating,
      });
    }
  }

  /**
   * Gets a user's rating on one game.
   *
   * @param {string} gameID The ID of the game
   * @param {string} userID The ID of the user
   * @returns {Promise<RatingClass | null>} Returns the rating, or null if the
   * user has not rated that game
   *
   * @example
   * const gameID = "gameID";
   * const userID = "userID";
   * const rating = await databaseService.getSingleRating(gameID, userID);
   * console.log(rating.userID); // "userID"
   */
  async getSingleRating(gameID, userID) {
    const q = query(
      collection(db, 'rating'),
      where('gameID', '==', gameID),
      where('userID', '==', userID),
      limit(1),
    );
    const querySnapshot = await getDocs(q);
    let rating = null;
    querySnapshot.forEach((rate) => {
      rating = new RatingClass(
        rate.id,
        rate.data().userID,
        rate.data().gameID,
        rate.data().gameName,
        rate.data().ratingValue,
      );
    });
    return rating;
  }

  /**
   * Creates a new list in the database. Optionally, you can add games to the list when creating it.
   *
   * @param {string} userID The ID of the user creating the list.
   * @param {string} title The title of the list.
   * @param {string[]} gameIDs An (optional) array of game IDs to add to the list.
   * @returns {Promise<string>} The document ID of the newly created list (in firebase).
   *
   * @example
   * const userID = auth.currentUser.uid;
   * const title = "List Title 1";
   * const listID = await databaseService.createList(userID, title);
   * console.log(listID); // "listID"
   *
   * @example
   * const userID = "userID";
   * const title = "List Title 2";
   * const listID = await databaseService.createList(userID, title, [game.gameID]);
   * console.log(listID); // "listID"
   *
   * @example
   * const userID = "userID";
   * const title = "List Title 3";
   * const gameIDs = games.map((game) => game.gameID);
   * const listID = await databaseService.createList(userID, title, gameIDs);
   * console.log(listID); // "listID"
   */
  async createList(userID, title, gameIDs = []) {
    const nonEmptyGameIDs = gameIDs.filter(
      (gameID) => gameID !== '' && gameID !== null,
    );
    const docRef = await addDoc(collection(db, 'list'), {
      userID,
      title,
      gameIDs: nonEmptyGameIDs,
    });
    return docRef.id;
  }

  /**
   * Retrieves lists associated with a specific user ID from the database.
   *
   * @param {string} userID The ID of the user.
   * @returns {Promise<ListClass[]>} A promise that resolves to an array of ListClass objects.
   *
   * @example
   * const userID = "userID";
   * const lists = await databaseService.getLists(userID);
   * console.log(lists); // [ListClass, ListClass, ...]
   */
  async getLists(userID) {
    const allGames = await this.getAllGames();

    const lists = [];
    const q = query(collection(db, 'list'), where('userID', '==', userID));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((document) => {
      const { gameIDs } = document.data();
      lists.push(
        new ListClass(
          document.id,
          userID,
          document.data().title,
          gameIDs
            .map((gameID) => allGames.find((game) => game.gameID === gameID))
            .filter((game) => game !== undefined),
        ),
      );
    });
    return lists;
  }

  /**
   * Retrieves lists associated with a specific user ID from the database.
   * Won't include the games in the list.
   *
   * @param {string} userID The ID of the user
   * @returns {Promise<ListClass[]>} A promise that resolves to an array of ListClass objects
   *
   * @example
   * const userID = "userID";
   * const lists = await databaseService.getLists(userID);
   * console.log(lists); // [ListClass, ListClass, ...] (without games)
   */
  async getListNames(userID) {
    const lists = [];
    const q = query(collection(db, 'list'), where('userID', '==', userID));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((document) => {
      lists.push(new ListClass(document.id, userID, document.data().title));
    });
    return lists;
  }

  /**
   * Removes a game from a list in the database, if the user is the owner of the list.
   *
   * @param {string} userID The ID of the user
   * @param {string} listID The ID of the list
   * @param {string} gameID The ID of the game to be removed
   * @returns {Promise<boolean>} A promise that resolves to true when the game is no longer in the document, or false otherwise.
   * Will return true even if the game wasn't in the document to begin with.
   *
   * @example
   * const userID = auth.currentUser.uid;
   * const listID = "listID";
   * const gameID = "gameID";
   * const result = await databaseService.removeGameFromList(userID, listID, gameID);
   * console.log(result); // true
   * // If the game was not in the list, the result will still be true
   * const result = await databaseService.removeGameFromList(userID, listID, gameID);
   * console.log(result); // true
   */
  async removeGameFromList(userID, listID, gameID) {
    const q = doc(collection(db, 'list'), listID);
    const document = await getDoc(q);
    if (document.exists() && document.data().userID === userID) {
      if (document.data().gameIDs.includes(gameID)) {
        await updateDoc(q, {
          gameIDs: arrayRemove(gameID),
        });
      }
      return true;
    }
    return false;
  }

  /**
   * Adds a game to a list in the database, if the user is the owner of the list.
   *
   * @param {string} userID The ID of the user
   * @param {string} listID The ID of the list
   * @param {string} gameID The ID of the game to be added
   * @returns {Promise<boolean>} A promise that resolves to true when the game is in the document, or false otherwise.
   * Will return false if the game is already in the document.
   *
   * @example
   * const userID = auth.currentUser.uid;
   * const listID = "listID";
   * const gameID = "gameID";
   * const result = await databaseService.addGameToList(userID, listID, gameID);
   * console.log(result); // true
   * // If the game is already in the list, the result will be false, as we don't want duplicates
   * const result = await databaseService.addGameToList(userID, listID, gameID);
   * console.log(result); // false
   */
  async addGameToList(userID, listID, gameID) {
    const q = doc(collection(db, 'list'), listID);
    const document = await getDoc(q);
    if (
      document.exists() &&
      document.data().userID === userID &&
      !document.data().gameIDs.includes(gameID)
    ) {
      await updateDoc(q, {
        gameIDs: arrayUnion(gameID),
      });
      return true;
    }
    return false;
  }

  /**
   * Deletes a list from the database, if the user is the owner of the list.
   *
   * @param {string} userID The ID of the user
   * @param {string} listID The ID of the list
   * @returns {Promise<boolean>} A promise that resolves to true when the list is deleted, or false otherwise.
   *
   * @example
   * const userID = auth.currentUser.uid;
   * const listID = "listID";
   * const result = await databaseService.deleteList(userID, listID);
   * console.log(result); // true
   * // If the list doesn't exist, the result will be false
   * const result = await databaseService.deleteList(userID, listID);
   * console.log(result); // false
   */
  async deleteList(userID, listID) {
    const q = doc(collection(db, 'list'), listID);
    const document = await getDoc(q);
    if (document.exists() && document.data().userID === userID) {
      await deleteDoc(q);
      return true;
    }
    return false;
  }

  /**
   * Updates a list in the database (based on its ID). Modifies the title and gameIDs of the list.
   *
   * @param {string} userID The ID of the user performing the update
   * @param {ListClass} list The list to update to
   * @returns {Promise<boolean>} A promise that resolves to true if the update was successful, false otherwise
   *
   * @example
   * const list = new ListClass(...);
   * const result = await databaseService.updateList(auth.currentUser.uid, list);
   * console.log(result); // true
   */
  async updateList(userID, list) {
    const q = doc(collection(db, 'list'), list.listID);
    const document = await getDoc(q);
    if (document.exists() && document.data().userID === userID) {
      await updateDoc(q, {
        title: list.title,
        gameIDs: list.games.map((game) => game.gameID),
      });
      return true;
    }
    return false;
  }
}

const databaseService = new DatabaseService();
export default databaseService;
