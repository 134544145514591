/**
 * Represents a game object. Used to store game data.
 * @class
 * @example
 * const game = new GameClass(
 *  "gameID",
 *  "Game Title",
 *  "Game Description",
 *  "2-4",
 *  4,
 *  ["Strategy", "Card"],
 *  "userID"
 * );
 */
export default class GameClass {
  /**
   * @param {string} gameID The ID of the game.
   * @param {string} title The title of the game.
   * @param {string} description The description of the game.
   * @param {string} numberOfPeople The number of people the game is for. (e.g. "2+", "4" or "4-6")
   * @param {number} rating The rating of the game.
   * @param {string[]} categories The categories of the game.
   * @param {string} creatorID The ID of the creator of the game.
   */
  constructor(
    gameID,
    title,
    description,
    numberOfPeople,
    rating,
    categories,
    creatorID,
  ) {
    this.gameID = gameID;
    this.title = title;
    this.description = description;
    this.numberOfPeople = numberOfPeople;
    this.rating = rating;
    this.categories = categories;
    this.creatorID = creatorID;
  }
}
