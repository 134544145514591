import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import AdCard from '../components/AdCard/AdCard';
import AreYouSure from '../components/Admin-components/AreYouSure'; // Import AreYouSure
import GamesCard from '../components/GamesCard/GamesCard';
import CreateGameCard from '../components/NewGame/CreateGameCard';
import storageService from '../services/StorageService';

function HomePage({ games, searchTerm, favoriteGames, setFavoriteGames }) {
  const [updatedGames, setUpdatedGames] = useState([]);
  const [showAreYouSure, setShowAreYouSure] = useState(false); // Add this state variable
  const [gameIdToDelete, setGameIdToDelete] = useState(null); // Add this state variable
  const [ads, setAds] = useState([]);

  let gameCardCount = 0;

  useEffect(() => {
    const filteredGames = games.filter((game) =>
      game.title.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setUpdatedGames(filteredGames);
  }, [games, searchTerm]);

  function onDelete(deletedGameID) {
    const newGames = updatedGames.filter(
      (game) => game.gameID !== deletedGameID,
    );
    setUpdatedGames(newGames);
    setShowAreYouSure(false); // Hide AreYouSure when a game is deleted
  }

  function closeAreYouSure() {
    setShowAreYouSure(false);
  }

  function toggleAreYouSure(gameId) {
    setShowAreYouSure(!showAreYouSure);
    setGameIdToDelete(gameId);
  }

  /**
   * Fetches all ads from the storageService and randomizes the order of the ads.
   */
  async function fetchAndRandomizeAds() {
    const adArrayPromises = await storageService.fetchAllImageDownloadUrls();
    const adArray = await Promise.all(adArrayPromises);
    adArray.sort(() => Math.random() - 0.5);
    setAds(adArray);
  }

  /*
   * Picks an ad from the ads-state.
   */
  function pickAd() {
    if (ads.length === 0) {
      return null;
    }
    const index = Math.floor((gameCardCount / 6) % ads.length);
    return ads[index];
  }

  useEffect(() => {
    fetchAndRandomizeAds();
  }, []);

  return (
    <div className="flex flex-row flex-wrap justify-center m-8 lg:mx-4 gap-8">
      <div className="flex w-full min-h-[170px] lg:w-[47%]">
        <CreateGameCard />
      </div>
      {updatedGames.map((game, index) => {
        const content = (
          <GamesCard
            game={game}
            index={index}
            toggleAreYouSure={() => toggleAreYouSure()}
            favoriteGames={favoriteGames}
            setFavoriteGames={setFavoriteGames}
          />
        );

        // An AdCard is displayed every 6th GameCard.
        if (gameCardCount % 6 === 0 && pickAd() !== null) {
          gameCardCount += 1;
          return [
            <div key={game.gameID} className="flex w-full lg:w-[47%]">
              <AdCard adUrl={pickAd()} />
            </div>, // Displays an AdCard

            <div key={game.gameID} className="flex w-full lg:w-[47%]">
              {content}
            </div>, // Displays a GameCard
          ];
        }
        gameCardCount += 1;
        return (
          <div key={game.gameID} className="flex w-full lg:w-[47%]">
            {content}
          </div>
        ); // Displays only a GameCard if the AdCard condition is not met.
      })}
      {showAreYouSure && (
        <AreYouSure
          onDelete={() => onDelete()}
          gameIdToDelete={gameIdToDelete}
          closeAreYouSure={() => closeAreYouSure()}
        />
      )}
    </div>
  );
}

HomePage.propTypes = {
  games: PropTypes.arrayOf(
    PropTypes.shape({
      gameID: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      rating: PropTypes.number,
      creatorID: PropTypes.string,
    }),
  ).isRequired,
  searchTerm: PropTypes.string.isRequired,
  favoriteGames: PropTypes.arrayOf(
    PropTypes.shape({
      gameID: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      rating: PropTypes.number,
      creatorID: PropTypes.string,
    }),
  ).isRequired,
  setFavoriteGames: PropTypes.func.isRequired,
};

export default HomePage;
