import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import GamesCard from '../components/GamesCard/GamesCard';
import UserCommentsAndRatings from '../components/UserCommentsAndRatings'; // Rating import
import { auth } from '../config/firebase';
import databaseService from '../services/DatabaseService';
import SpinTheWheelPopup from '../components/SpinTheWheel/SpinTheWheelPopup';

function ProfilePage({ favoriteGames, setFavoriteGames }) {
  const [myGames, setMyGames] = useState([]);
  const user = auth.currentUser;

  const [spinTheWheelVisible, setSpinTheWheelVisible] = useState(false);

  useEffect(() => {
    databaseService.getGamesByCreatorID(auth.currentUser.uid).then((games) => {
      setMyGames(games);
    });
  }, []);

  const handeSpinTheWheelClick = () => {
    setSpinTheWheelVisible(true);
  };

  const handleCloseClick = () => {
    setSpinTheWheelVisible(false);
  };

  return (
    <div className="pl-8 w-full pr-8 pb-5">
      {spinTheWheelVisible && (
        <SpinTheWheelPopup
          closePopup={handleCloseClick}
          favoriteGames={favoriteGames}
        />
      )}
      <h1 className="text-3xl font-bold py-8">Min profil</h1>
      <div className="flex flex-row align-middle gap-4 text-2xl">
        <img
          src={user.photoURL || '/profilepic.png'}
          alt="Profilbilde"
          className="w-32 rounded-md"
        />
        <div className="flex flex-col justify-evenly font-semibold">
          <p>Navn:</p>
          <p>E-post:</p>
        </div>
        <div className="flex flex-col justify-evenly font-thin">
          <p>{user.displayName || 'Ikke oppgitt'}</p>
          <p>{user.email || 'Ikke oppgitt'}</p>
        </div>
      </div>

      <h2 className="text-2xl font-bold py-4 pt-8">Mine innlegg</h2>
      {myGames.length === 0 ? (
        <>
          <p>Du har ikke lagt til noen spill enda.</p>
          <p>
            Gå til{' '}
            <Link
              to="/newgame"
              className="border-b-2 border-blue-500 text-blue-500 hover:border-blue-700 hover:text-blue-700"
            >
              Lag nytt spill
            </Link>{' '}
            for å legge til et nytt spill.
          </p>
        </>
      ) : (
        <div className="flex flex-wrap">
          {myGames.map((game, index) => (
            <div
              key={game.gameID}
              className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 p-2"
            >
              <GamesCard
                game={game}
                index={index}
                favoriteGames={favoriteGames}
                setFavoriteGames={setFavoriteGames}
              />
            </div>
          ))}
        </div>
      )}

      <div className="flex flex-row mt-10 justify-between align-middle gap-4 mb-5">
        <h2 className="text-2xl font-bold py-4 pt-8">Favoritter</h2>
        <button
          type="button"
          className="text-lg border dark:border-black rounded-lg p-4 m-2 bg-primary hover:bg-[#B0C4DE] dark:bg-slate-700 dark:hover:bg-slate-800 mt-4 self-end"
          onClick={handeSpinTheWheelClick}
        >
          Velg tilfeldig lek
        </button>
      </div>

      <div className="flex flex-wrap">
        {favoriteGames.map((game, index) => (
          <div
            key={game.gameID}
            className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 p-2"
          >
            <GamesCard
              game={game}
              index={index}
              favoriteGames={favoriteGames}
              setFavoriteGames={setFavoriteGames}
            />
          </div>
        ))}
      </div>
      <h2 className="text-2xl font-bold py-4 pt-8">
        Mine vurderinger og kommentarer
      </h2>
      <UserCommentsAndRatings userID={user.uid} />
    </div>
  );
}

ProfilePage.propTypes = {
  favoriteGames: PropTypes.arrayOf(
    PropTypes.shape({
      gameID: PropTypes.string,
      gameName: PropTypes.string,
      description: PropTypes.string,
      numberOfPeople: PropTypes.string,
      rating: PropTypes.number,
      categories: PropTypes.arrayOf(PropTypes.string),
      creatorID: PropTypes.string,
    }),
  ).isRequired,
  setFavoriteGames: PropTypes.func.isRequired,
};

export default ProfilePage;
