import PropTypes from 'prop-types';
import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../config/firebase';
import databaseService from '../../services/DatabaseService';

function GamesCard({
  game,
  index,
  toggleAreYouSure,
  favoriteGames = [],
  setFavoriteGames,
}) {
  const colors = ['bg-indigo-100', 'bg-sky-100', 'bg-slate-100', 'bg-blue-100'];
  const darkColors = [
    'bg-[#1D334A]',
    'bg-[#424163]',
    'bg-[#34545E]',
    'bg-[#34485E]',
  ];
  const color = colors[index % colors.length];
  const darkColor = `dark:${darkColors[index % darkColors.length]}`;

  const [isAdmin, setIsAdmin] = useState(false);
  const [isHeartClicked, setIsHeartClicked] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        databaseService.isAdmin(user.uid).then((value) => setIsAdmin(value));
      }
    });
  }, []);

  function handleDeleteClick(event) {
    event.preventDefault();
    toggleAreYouSure(game.gameID); // Call toggleAreYouSure when delete button is clicked
  }

  const handleFavorite = async (e) => {
    e.preventDefault(); // make the Link to game not work when clicking on the heart
    const added = await databaseService.addGameToFavorite(game.gameID);
    if (added) {
      setFavoriteGames([...favoriteGames, game]);
    } else {
      setFavoriteGames(
        favoriteGames.filter(
          (favoriteGame) => favoriteGame.gameID !== game.gameID,
        ),
      );
    }
  };

  useEffect(() => {
    setIsHeartClicked(false);
    if (favoriteGames) {
      for (let i = 0; i < favoriteGames.length; i += 1) {
        if (favoriteGames[i].gameID === game.gameID) {
          setIsHeartClicked(true);
          return;
        }
      }
    }
  }, [favoriteGames, game.gameID]);

  return (
    <Link
      to={`/game/${game.gameID}`}
      relative="path"
      className="w-full h-full"
      draggable="false"
    >
      <div
        className={`flex flex-col h-full justify-center rounded-lg border border-transparent p-12 text-center relative ${color} ${darkColor} hover:border-black dark:hover:border-white`}
        draggable="false"
      >
        <div className="game-info">
          <div className="game-title">
            <h3 className="text-xl font-semibold">{game.title}</h3>
          </div>
          <div className="game-rating">
            <p className="text-weakText dark:text-weakText-dark">
              Rating: {game.rating}{' '}
              <span role="img" aria-label="star">
                &#9733;
              </span>
            </p>
          </div>
          <div className="game-players">
            <p className="text-weakText dark:text-weakText-dark">
              Spillere: {game.numberOfPeople}
            </p>
          </div>
        </div>
        {window.location.pathname !== '/admin' &&
          window.location.pathname !== '/myLists' && (
            <div className="absolute top-3 right-4 mt-2 mr-2">
              <button type="button" onClick={(e) => handleFavorite(e)}>
                <img
                  src={isHeartClicked ? '/red-heart.png' : '/heart.png'} // Change heart to red when clicked on
                  alt="hjerte"
                  className="max-h-10 cursor-pointer"
                />
              </button>
            </div>
          )}
        {isAdmin && window.location.pathname === '/admin' && (
          <div className="absolute top-0 right-0 flex">
            <button onClick={handleDeleteClick} type="button">
              <img src="/bin.png" alt="bin" className="w-6 h-6 mt-2 mr-2" />
            </button>
          </div>
        )}
      </div>
    </Link>
  );
}

GamesCard.defaultProps = {
  toggleAreYouSure: () => {},
  favoriteGames: [],
};

GamesCard.propTypes = {
  game: PropTypes.shape({
    gameID: PropTypes.string,
    title: PropTypes.string,
    rating: PropTypes.number,
    numberOfPeople: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  toggleAreYouSure: PropTypes.func,
  favoriteGames: PropTypes.arrayOf(
    PropTypes.shape({
      gameID: PropTypes.string,
      title: PropTypes.string,
      rating: PropTypes.number,
      numberOfPeople: PropTypes.string,
    }),
  ),
  setFavoriteGames: PropTypes.func.isRequired,
};

export default GamesCard;
