import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

function Rate({ rate, currentRating }) {
  function hoverFill(rating) {
    for (let id = 1; id <= 5; id += 1) {
      if (id <= rating) {
        document.getElementById(id.toString()).textContent = '★';
      } else {
        document.getElementById(id.toString()).textContent = '☆';
      }
    }
  }

  function reset() {
    for (let id = 1; id <= 5; id += 1) {
      if (id <= currentRating) {
        document.getElementById(id.toString()).textContent = '★';
      } else {
        document.getElementById(id.toString()).textContent = '☆';
      }
    }
  }

  useEffect(() => {
    reset();
  }, [currentRating]);

  return (
    <section className="flex flex-row flex-wrap bg-admin dark:bg-admin-dark items-center justify-center w-full p-6 rounded-xl gap-2">
      <p className="text-3xl truncate">Gi rating</p>
      <div
        onMouseLeave={() => reset()}
        className="flex flex-row text-5xl cursor-pointer"
      >
        {[1, 2, 3, 4, 5].map((rating) => (
          <button
            key={rating}
            id={rating.toString()}
            type="button"
            aria-label="Empty star"
            onMouseOver={() => hoverFill(rating)}
            onFocus={() => hoverFill(rating)}
            onClick={() => rate(rating)}
          >
            ☆
          </button>
        ))}
      </div>
    </section>
  );
}

Rate.propTypes = {
  rate: PropTypes.func.isRequired,
  currentRating: PropTypes.number.isRequired,
};

export default Rate;
