import PropTypes from 'prop-types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { auth } from '../config/firebase';
import CommentClass from '../services/CommentClass';
import databaseService from '../services/DatabaseService';

function WriteReport({ onClose, gameID, gameName }) {
  const [description, setDescription] = useState('');

  function sendReport() {
    if (description !== '') {
      databaseService.sendReport(
        new CommentClass(
          null,
          auth.currentUser.uid,
          auth.currentUser.displayName,
          auth.currentUser.email,
          gameID,
          gameName,
          description.trim(),
          new Date(),
        ),
      );
      onClose();
    } else {
      toast.error('Du må beskrive utfordringen for å kunne sende en rapport');
    }
  }

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-opacity-80 bg-black z-50">
      <div className="absolute bg-admin dark:bg-admin-dark w-1/2 flex flex-col h-[30rem] rounded-lg border dark:border-black p-5">
        <div className="absolute w-full flex justify-end right-3 top-3">
          <button
            type="button"
            aria-label="Lukk rapporteringsskjema"
            className="flex justify-center items-center hover:bg-primary/50 w-[3rem] h-[3rem] rounded text-4xl"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-10 h-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <h1 className="text-4xl mb-4 ">Rapporter lek</h1>
        <div className="flex flex-col w-full">
          <textarea
            id="Description"
            className="items-center w-full h-[300px] bg-quaternary dark:bg-quaternary-dark border dark:border-black text-2xl rounded-lg  p-5"
            placeholder="Beskrivelse..."
            onChange={(e) => setDescription(e.target.value)}
          />
          <button
            type="submit"
            className="border dark:border-black w-1/5 rounded-lg p-4 bg-primary dark:bg-slate-700 dark:hover:bg-slate-800 mt-4 hover:bg-primary/50 self-end"
            onClick={sendReport}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

WriteReport.propTypes = {
  onClose: PropTypes.func.isRequired,
  gameID: PropTypes.string.isRequired,
  gameName: PropTypes.string.isRequired,
};

export default WriteReport;
