import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { auth } from '../config/firebase';
import databaseService from '../services/DatabaseService';
import Plus from './Icons/Plus';
import Checkmark from './Icons/Checkmark';

function AddToListPopup({ game, hidePopup }) {
  const [lists, setLists] = useState([]);
  const [newGameTitle, setNewGameTitle] = useState('');

  async function handleClick(listID) {
    if (
      lists.find((list) => list.listID === listID).games.includes(game.gameID)
    )
      return;

    // Deep copy of lists
    const backup = JSON.parse(JSON.stringify(lists));

    setLists(
      lists.map((list) => {
        if (list.listID === listID) {
          list.games.push(game);
        }
        return list;
      }),
    );

    databaseService
      .addGameToList(auth.currentUser.uid, listID, game.gameID)
      .then((result) => {
        if (!result) {
          setLists(backup);
        }
      });
  }

  async function addList() {
    if (newGameTitle === '') return;

    const listID = await databaseService.createList(
      auth.currentUser.uid,
      newGameTitle,
      [game.gameID],
    );

    if (listID) {
      setLists([
        {
          listID,
          title: newGameTitle,
          games: [game],
        },
        ...lists,
      ]);
      setNewGameTitle('');
      window.document.getElementById('inputfield').value = '';
    }
  }

  useEffect(() => {
    databaseService.getLists(auth.currentUser.uid).then((_lists) => {
      setLists(_lists);
    });
  }, []);

  return (
    // Disable because the onclick is only to stop propagation
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className="absolute z-10 bg-admin dark:bg-admin-dark h-[30rem] max-w-[30rem] w-1/2 rounded-lg border dark:border-black p-8 flex flex-col gap-4"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex flex-row justify-between align-middle">
        <h2 className="text-3xl font-bold">Legg til i liste</h2>
        <button
          className="flex justify-center items-center hover:bg-primary/50 w-[3rem] h-[3rem] rounded text-4xl mt-[-0.5rem]"
          type="button"
          aria-label="Lukk popup"
          onClick={() => hidePopup()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-10 h-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="overflow-y-auto flex flex-col gap-4">
        {lists.map((list) => {
          const addable = !list.games.some(
            (_game) => _game.gameID === game.gameID,
          );

          return (
            <button
              key={list.listID}
              className="p-4 bg-category dark:bg-category-dark rounded flex flex-row justify-between"
              onKeyDown={(e) => e.key === 'Enter' && handleClick(list.listID)}
              type="button"
              aria-label="Legg til i liste"
              style={{
                cursor: addable ? 'pointer' : 'default',
              }}
              onClick={() => handleClick(list.listID)}
            >
              <p className="text-lg">{list.title}</p>
              {addable ? <Plus /> : <Checkmark />}
            </button>
          );
        })}
      </div>
      <div className="mt-auto flex flex-row gap-4">
        <input
          type="text"
          id="inputfield"
          className="p-4 rounded border dark:border-black w-2/3 h-full dark:text-black"
          placeholder="Navn på ny liste..."
          onChange={(e) => setNewGameTitle(e.target.value)}
        />
        <button
          className="p-4 rounded bg-category dark:bg-category-dark w-1/3 h-full font-semibold"
          type="button"
          onClick={() => addList()}
        >
          Opprett liste
        </button>
      </div>
    </div>
  );
}

AddToListPopup.propTypes = {
  game: PropTypes.shape({
    gameID: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  hidePopup: PropTypes.func.isRequired,
};

export default AddToListPopup;
