/**
 * Represents a rating object. Used to store rating data.
 * @class
 * @example
 * const game = new RatingClass(
 *     "ratingID",
 *     "userID",
 *     "gameID",
 *     "Game Name",
 *     4,
 * );
 */
export default class RatingClass {
  /**
   * @param {string} ratingID The ID of the rating
   * @param {string} userID The ID of the user.
   * @param {string} gameID The ID of the game.
   * @param {string} gameName The name of the game.
   * @param {number} ratingValue The rating of the game.
   */
  constructor(ratingID, userID, gameID, gameName, ratingValue) {
    this.ratingID = ratingID;
    this.userID = userID;
    this.gameID = gameID;
    this.gameName = gameName;
    this.ratingValue = Number(ratingValue);
  }
}
