import React from 'react';

function BackButton() {
  function goBack() {
    if (document.referrer.endsWith('/newgame')) {
      window.open('/', '_self');
    } else {
      window.history.back();
    }
  }

  return (
    <button
      onClick={() => goBack()}
      type="button"
      className="
                flex flex-col items-center justify-end
                bg-slate-300 dark:bg-slate-500 rounded-lg p-3 w-[120px] h-[50px]
                gap-2 hover:gap-4
                border hover:border-black dark:border-black dark:hover:border-white
                ease-in-and-out duration-300"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
        />
      </svg>
      Tilbake
    </button>
  );
}

export default BackButton;
