import PropTypes from 'prop-types';
import React from 'react';
import SpinTheWheel from '../SpinTheWheel';
import GameClass from '../../services/GameClass';

function SpinTheWheelPopup({ closePopup, favoriteGames }) {
  return (
    <section className="fixed z-10 inset-0 flex justify-center items-center h-screen w-screen">
      <div
        onClick={() => closePopup()}
        role="button"
        aria-label="Close"
        tabIndex={0}
        onKeyDown={(e) => e.key === 'Escape' && closePopup()}
        className="h-full w-full bg-gray-700 opacity-50"
      />
      <div className="fixed flex justify-center items-center p-8 rounded-lg shadow-2xl shadow-black/70 border bg-admin dark:bg-admin-dark">
        <button
          onClick={() => closePopup()}
          type="button"
          aria-label="Close"
          className="absolute z-10 right-0 top-0 flex justify-center items-center hover:bg-quaternary/30 m-2 w-[3rem] h-[3rem] rounded text-4xl"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-10 h-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
        <SpinTheWheel favoriteGames={favoriteGames} closePopup={closePopup} />
      </div>
    </section>
  );
}

SpinTheWheelPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  favoriteGames: PropTypes.arrayOf(GameClass).isRequired,
};

export default SpinTheWheelPopup;
