import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import { auth } from './config/firebase';
import './index.css';
import AdminPage from './pages/AdminPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import MyListsPage from './pages/MyListsPage';
import ProfilePage from './pages/ProfilePage';
import SignupPage from './pages/SignupPage';
import Game from './pages/game/[gameID]';
import NewGame from './pages/game/newgame';
import databaseService from './services/DatabaseService';

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [games, setGames] = useState([]);
  const [user, setUser] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [favoriteGames, setFavoriteGames] = useState([]);

  useEffect(() => {
    onAuthStateChanged(auth, (_user) => {
      setUser(_user);
      setLoaded(true);

      if (_user !== null) {
        databaseService
          .isAdmin(_user.uid)
          .then((_isAdmin) => setIsAdmin(_isAdmin));
        databaseService
          .getFavoritedGames(auth.currentUser.uid)
          .then((_games) => {
            setFavoriteGames(_games);
          });
        databaseService.getAllGames().then((_games) => {
          setGames(_games);
        });
      }
    });
  }, []);

  /**
   * Fetcher games fra databasen basert på valgte filtere og oppdaterer games-state.
   * Hvis ingen filtere blir valgt hentes alle spill.
   * Sørger for dynamisk oppdatering av games-state når filtere/kategorier endres.
   *
   * @param {Array<string>} filters - en array med valgte filtere
   *
   * @see databaseService.getAllGames
   * @see databaseService.getGamesByCategories
   * @see setGames
   */
  const fetchFilteredGames = async (filters) => {
    if (filters.length === 0) {
      const fetchedGames = await databaseService.getAllGames();
      setGames(fetchedGames);
    } else {
      const fetchedGames = await databaseService.getGamesByCategories(filters);
      setGames(fetchedGames);
    }
  };

  useEffect(() => {
    fetchFilteredGames(selectedFilters);
  }, [selectedFilters]);

  function checkInitialMode() {
    if (localStorage.getItem('mode') === 'dark') {
      window.document.documentElement.classList.add('dark');
    }
  }

  return (
    <Router>
      <script type="module">{checkInitialMode()}</script>
      <Toaster />
      {loaded && user === null && (
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
        </Routes>
      )}

      {loaded && user !== null && (
        <div className="flex dark:bg-gray-900 dark:text-white min-h-screen">
          <div className="fixed top-0 left-0 w-64 bg-gray-800 text-white h-full p-4">
            <Navbar
              onSearch={setSearchTerm}
              onFiltersChange={setSelectedFilters}
            />
          </div>
          <div className="ml-64 flex-grow">
            <Routes>
              <Route
                path="/"
                element={
                  <HomePage
                    games={games}
                    searchTerm={searchTerm}
                    favoriteGames={favoriteGames}
                    setFavoriteGames={setFavoriteGames}
                  />
                }
              />
              <Route
                path="/profile"
                element={
                  <ProfilePage
                    favoriteGames={favoriteGames}
                    setFavoriteGames={setFavoriteGames}
                  />
                }
              />
              <Route
                path="/game/:gameID"
                element={
                  <Game
                    favoriteGames={favoriteGames}
                    setFavoriteGames={setFavoriteGames}
                  />
                }
              />
              <Route
                path="/mylists"
                element={
                  <MyListsPage
                    favoriteGames={favoriteGames}
                    setFavoriteGames={setFavoriteGames}
                  />
                }
              />
              {isAdmin && (
                <Route
                  path="/admin"
                  element={<AdminPage games={games} setGames={setGames} />}
                />
              )}
              <Route path="/newgame" element={<NewGame />} />
            </Routes>
          </div>
        </div>
      )}
    </Router>
  );
}

export default App;
