import React, { useState } from 'react';
import toast from 'react-hot-toast';

function ShareGameButton() {
  const [showPopup, setShowPopup] = useState(false);

  // denne funksjonen kopierer lenken til spillet til utklippstavlen
  // og viser en popup som forsvinner etter 2 sekunder.

  const handleShare = () => {
    const currentURL = window.location.href;
    navigator.clipboard
      .writeText(currentURL)
      .then(() => {
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 2000); // Popup forsvinner etter 2 sekunder
      })
      .catch(() => {
        toast.error('Kunne ikke kopiere lenken til utklippstavlen');
      });
  };

  return (
    <div className="relative">
      <button type="button" onClick={handleShare}>
        <img src="/share.png" alt="Share" className="max-h-10 cursor-pointer" />
      </button>
      {showPopup && (
        <>
          <div className="fixed inset-0 bg-white opacity-25" />
          <div className="fixed bottom-4 right-4">
            <div className="bg-gray-800 rounded-lg shadow-lg p-8">
              <span className="text-white">
                Lenke er kopiert til utklippstavle
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ShareGameButton;
