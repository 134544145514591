import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import AddToList from '../../components/AddToList';
import CommentBox from '../../components/CommentBox';
import CommentDisplay from '../../components/CommentDisplay';
import GameDescription from '../../components/GameDescription';
import Rate from '../../components/Rate';
import { auth } from '../../config/firebase';
import databaseService from '../../services/DatabaseService';
import RatingClass from '../../services/RatingClass';

function Game({ favoriteGames, setFavoriteGames }) {
  const { pathname } = window.location;
  const gameID = pathname.substring(6);

  const [comments, setComments] = useState([]);
  const [game, setGame] = useState(null);
  const [currentRating, setCurrentRating] = useState(0);
  const [lastRating, setLastRating] = useState(0);
  const [ratingFailed, setRatingFailed] = useState(false);

  useEffect(() => {
    databaseService.getGameById(gameID).then((_game) => {
      setGame(_game);
    });
    databaseService.getCommentsByGameID(gameID).then((_comments) => {
      setComments(_comments);
    });
    databaseService
      .getSingleRating(gameID, auth.currentUser.uid)
      .then((rating) => {
        if (rating != null) {
          setCurrentRating(rating.ratingValue);
          setLastRating(rating.ratingValue);
        }
      });
  }, [gameID]);

  const handleFavorite = async () => {
    const added = await databaseService.addGameToFavorite(gameID);
    if (added) {
      setFavoriteGames([...favoriteGames, game]);
    } else {
      setFavoriteGames(
        favoriteGames.filter((favoriteGame) => favoriteGame.gameID !== gameID),
      );
    }
  };

  /**
   * Update a comment in the comments array with a new text.
   *
   * (Only updates on the client side)
   *
   * @param {number} index The index of the comment to update.
   * @param {string} newText The new text to update the comment with.
   */
  const updateComment = (index, newText) => {
    setComments(
      comments.map((comment, i) => {
        if (i === index) {
          return {
            ...comment,
            text: newText,
            edited: true,
          };
        }
        return comment;
      }),
    );
  };

  const removeComment = (index) => {
    const updatedComments = comments.filter((_, i) => i !== index);
    setComments(updatedComments);
  };

  async function handleRating(ratingValue) {
    const rating = new RatingClass(
      null,
      auth.currentUser.uid,
      game.gameID,
      game.title,
      ratingValue,
    );
    setCurrentRating(ratingValue);
    try {
      await databaseService.addRating(rating);
    } catch (e) {
      setRatingFailed(true);
      toast.error('Kunne ikke rate. Prøv igjen senere.');
    }
  }

  useEffect(() => {
    if (ratingFailed) {
      setCurrentRating(lastRating);
      setRatingFailed(false);
    }
  }, [ratingFailed, currentRating]);

  return (
    <div>
      {game && (
        <div className="flex flex-col gap-4 m-4">
          <GameDescription
            game={game}
            onFavoriteClicked={handleFavorite}
            favoriteGames={favoriteGames}
          />
          <CommentDisplay
            comments={comments}
            updateComment={updateComment}
            removeComment={removeComment}
          />
          {game && (
            <CommentBox
              game={game}
              setComments={(com) => setComments(com)}
              allComments={comments}
            />
          )}
          <div className="flex flex-row gap-3 mt-4">
            <Rate
              rate={(ratingValue) => handleRating(ratingValue)}
              currentRating={currentRating}
            />
            <AddToList game={game} />
          </div>
        </div>
      )}
    </div>
  );
}

Game.propTypes = {
  favoriteGames: PropTypes.arrayOf(
    PropTypes.shape({
      gameID: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setFavoriteGames: PropTypes.func.isRequired,
};

export default Game;
