import PropTypes from 'prop-types';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { auth } from '../config/firebase';
import databaseService from '../services/DatabaseService';

function CommentDisplay({ comments, updateComment, removeComment }) {
  const [editingIndex, setEditingIndex] = useState(null);
  const [editText, setEditText] = useState('');

  const handleEditClick = (index, comment) => {
    setEditingIndex(index);
    setEditText(comment.text);
  };

  const handleDismiss = () => {
    setEditingIndex(null);
    setEditText('');
  };

  const handleSave = async (index) => {
    const trimmedText = editText.trim();

    if (trimmedText === comments[index].text) {
      // Ingen endring
      setEditingIndex(null);
      setEditText('');
      return;
    }

    if (trimmedText === '') {
      toast.error('Kommentarfeltet kan ikke være tom!');
      return;
    }
    const changed = await databaseService.updateComment(
      auth.currentUser.uid,
      comments[index].ID,
      trimmedText,
    );
    if (changed) {
      updateComment(index, trimmedText);
      setEditingIndex(null); // Ut av redigeringsmodus
      setEditText('');
    } else {
      toast.error('Kunne ikke endre kommentar');
    }
  };

  const handleDeleteClick = (index, comment) => {
    databaseService
      .deleteComment(comment.ID, auth.currentUser.uid)
      .then((deleted) => {
        if (deleted) {
          removeComment(index);
        } else {
          toast.error('Kunne ikke slette kommentar');
        }
      });
  };

  return (
    <>
      {comments.map((comment, index) => (
        <div
          key={comment.ID}
          className="flex flex-col mw-full p-4 bg-admin dark:bg-admin-dark rounded-lg"
        >
          <div className="flex justify-between items-center mb-4 h-12">
            <div className="flex items-center gap-2">
              <span>👤</span>
              {comment.creatorName || comment.creatorEmail}
            </div>
            <div className="flex flex-row gap-4 items-center">
              {comment.creatorID === auth.currentUser.uid &&
                (editingIndex === index ? (
                  <>
                    <button
                      type="button"
                      className="py-1 px-3 h-9 bg-green-500 text-white rounded hover:bg-green-600"
                      onClick={() => handleSave(index)}
                    >
                      Lagre
                    </button>
                    <button
                      type="button"
                      className="py-1 px-3 h-9 bg-gray-500 text-white rounded hover:bg-gray-600"
                      onClick={() => handleDismiss()}
                    >
                      Avbryt
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="py-1 px-3 h-9 bg-red-500 text-white rounded hover:bg-red-600"
                      onClick={() => handleDeleteClick(index, comment)}
                    >
                      Slett
                    </button>
                    <button
                      type="button"
                      className="py-1 px-3 h-9 bg-blue-500 text-white rounded hover:bg-blue-600"
                      onClick={() => handleEditClick(index, comment)}
                    >
                      Rediger
                    </button>
                  </>
                ))}
              <div className="flex flex-col text-gray-700 dark:text-gray-300 text-right">
                <p>{comment.timestampAsString}</p>
                {comment.edited && <p>(Redigert)</p>}
              </div>
            </div>
          </div>
          <div className="border border-commentBorder dark:border-commentBorder-dark rounded p-2 bg-quaternary dark:bg-quaternary-dark text-gray-800 dark:text-gray-100">
            {editingIndex === index ? (
              <textarea
                value={editText}
                className="w-full bg-quaternary dark:bg-quaternary-dark"
                onChange={(e) => setEditText(e.target.value)}
              />
            ) : (
              <p className="whitespace-pre-line">{comment.text}</p>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

CommentDisplay.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.string.isRequired,
      creatorID: PropTypes.string.isRequired,
      creatorName: PropTypes.string,
      creatorEmail: PropTypes.string,
      gameID: PropTypes.string.isRequired,
      gameTitle: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      timestamp: PropTypes.instanceOf(Date).isRequired,
      timestampAsString: PropTypes.string.isRequired,
      edited: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  updateComment: PropTypes.func.isRequired,
  removeComment: PropTypes.func.isRequired,
};

export default CommentDisplay;
